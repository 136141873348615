import React, { useEffect, useState, useTransition } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { getusers } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';
import { useTranslation } from 'react-i18next';

import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Loader } from '../loadersAndIcons/Loader';

import { UpdateReportDateModal } from '../modals/UpdateReportDateModal';
import { WhoUpdateReportSheet } from '../WhoUpdateReportSheet';
import { Input } from '../../components/ui/input';

export type ViewRecord = {
    employeeName: string;
    workLogID: string;
    date: string;
    startTime: string;
    endTime: string;
    startDateTime: string;
    userID: string;
    sub: string;
    endDateTime: string;
    boardNames: string;
};

export function ReportDetailsTable({ reports }) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [boardNameFilter, setBoardNameFilter] = useState('');

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();



    const { t, i18n } = useTranslation('global');



    const columns: ColumnDef<ViewRecord>[] = [
        {
            accessorKey: "employeeName",
            header: t("resportDetailsColumn1"),
            cell: info => info.getValue(),
        },

        {
            accessorKey: "date",
            header: t("resportDetailsColumn2"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "startTime",
            header: t("resportDetailsColumn100"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "endTime",
            header: t("resportDetailsColumn200"),
            cell: info => info.getValue(),
        },
        // {
        //     accessorKey: "endDateTime",
        //     header: t("resportDetailsColumn3"),
        //     cell: info => info.getValue(),
        // },
        {
            accessorKey: "sub",
            header: t("resportDetailsColumn4"),
            cell: info => {
                const value = info.getValue() + "";
                console.log("value", value);
                const endDateTime = info.row.original.endDateTime;
                if (endDateTime === "00/00/00 00:00" || (typeof value === 'string' && value.startsWith("-"))) {
                    return "";
                } else {
                    return value;
                }
            },
        },



        {
            accessorKey: "boardName",
            header: t("resportDetailsColumn5"),
            cell: info => info.getValue(),

        },
        {
            accessorKey: "edit",
            header: t("resportDetailsColumn6"),
            cell: (info) => (

                <UpdateReportDateModal workLogID={info.row.original.workLogID} startDateTime={info.row.original.startTime} endDateTime={info.row.original.endTime} startedDate={info.row.original.startDateTime} />
            ),
        },
        {
            accessorKey: "isUpdated",
            header: t("resportDetailsColumn7"),
            cell: info => info.getValue() === "1" ? <WhoUpdateReportSheet workLogID={info.row.original.workLogID} /> : null,

        },


    ];


    const table = useReactTable({
        data: reports || [],
        columns,
        state: { sorting, globalFilter },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getCoreRowModel(),

        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        const fetchUserData = async () => {
            dispatch(getusers())

        };

        fetchUserData();
    }, []);


    // if (!reports) {
    //     return (
    //         <Loader />
    //     );
    // }
    const isRtl = i18n.language === 'he';




    return (
        <div className="w-full" dir={isRtl ? "rtl" : "ltr"}>
            <div className="flex items-center py-4" dir={isRtl ? "rtl" : "ltr"}>
            </div>
            <div className="rounded-md border" dir={isRtl ? "rtl" : "ltr"}>
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="text-center">
                                    {t("noResults")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getRowModel().rows.length} {t("of")} {reports?.length} {t("records")}.
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {t("prevButton")}
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {t("nextButton")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
