import React from 'react';
import { Pie } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

// Define the type for our chart data
interface PieChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
    }[];
}

// Define the props for our component
interface PieChartProps {
    data: PieChartData;
    options: ChartOptions;
}

const PieChartComponent: React.FC<PieChartProps> = ({ data, options }) => {
    return (
        <>
            {/* <h2>Bar Chart</h2> */}
            <Pie data={data} options={options} />
        </>
    );
};

export default PieChartComponent;
