"use client"

import { useTranslation } from 'react-i18next';
import * as React from "react"

import { cn } from "../lib/utils"
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu"
import { useNavigate } from "react-router-dom"




export function NavigationMenuDemo() {
    const rawUserType = localStorage.getItem('userType_rom');

    const userType = rawUserType ? rawUserType.replace(/^"|"$/g, '') : 'Unknown';
    const { i18n } = useTranslation();
    const [t] = useTranslation("global")
    const components = userType === "1" ? [
        {
            "title": t("navigationMenuObject3Title"),
            "href": "/reports",
            "description": t("navigationMenuObject3Description"),
        }
    ] : [
        {
            "title": t("navigationMenuObject1Title"),
            "href": "/users-manage",
            "description": t("navigationMenuObject1Description"),
        },
        {
            "title": t("navigationMenuObject2Title"),
            "href": "/users-data",
            "description": t("navigationMenuObject2Description"),
        },
        {
            "title": t("navigationMenuObject3Title"),
            "href": "/reports",
            "description": t("navigationMenuObject3Description"),
        },
        {
            "title": t("navigationMenuObject4Title"),
            "href": "/users",
            "description": t("navigationMenuObject4Description"),
        },
    ];
    const navigate = useNavigate()

    return (
        <NavigationMenu>
            <NavigationMenuList>


                <NavigationMenuItem  >

                    <NavigationMenuTrigger>{t("moreFeatures")}</NavigationMenuTrigger>
                    <NavigationMenuContent>
                        <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                            {components.map((component) => (
                                <ListItem onClick={() => navigate(component.href)}
                                    key={component.title}
                                    title={component.title}
                                >
                                </ListItem>
                            ))}
                        </ul>
                    </NavigationMenuContent>
                </NavigationMenuItem >
                < NavigationMenuItem >
                    <NavigationMenuLink className={navigationMenuTriggerStyle()} onClick={() => navigate("/home")}>
                        {t("appHeaderRoute1")}
                    </NavigationMenuLink>
                </ NavigationMenuItem>

            </NavigationMenuList >
        </NavigationMenu >
    )
}


const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}
                    {...props}
                >
                    <div className="text-sm font-medium leading-none">{title}</div>
                    <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {children}
                    </p>
                </a>
            </NavigationMenuLink>
        </li>
    )
})
ListItem.displayName = "ListItem"
