import React, { useState, useEffect } from 'react';
import { Button } from "../../components/ui/button"
import { TimerDisplay } from '../TimerDisplay';


import { useTranslation } from 'react-i18next';

import { taskService } from '../../services/task.service';
import { Loader } from '../loadersAndIcons/Loader';
import { TaskDescription } from './TaskDescription';
import ToastComponent from '../ToastComponent';

export interface ColumnValue {
    id: string;
    text: string;
}

export interface Task {
    id: string;
    name: string;
    column_values: ColumnValue[];
}

interface TaskDetails {
    boardID: string;
    itemID: string;
    numberValue: string;
}

interface TaskPreviewProps {
    task: Task;
    tasks: Task[];
}

export function TaskPreview({ task, tasks }: TaskPreviewProps) {
    const [t, i18n] = useTranslation("global")

    const timerKey = `timerStartTime-${task.id}`;
    const [toastProps, setToastProps] = useState<{
        variant: 'success' | 'destructive';
        title: string;
        description: string;
    } | null>(null);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [timer, setTimer] = useState<number>(0);
    function findRunningTaskKey() {
        for (const key in localStorage) {
            if (key.startsWith('timerStartTime-') && localStorage.getItem(key)) {
                return key.replace('timerStartTime-', '');
            }
        }
        return null;
    }
    const handleStartStop = async (task: Task) => {

        try {

            const runningTaskKey = findRunningTaskKey();
            if (runningTaskKey && runningTaskKey !== task.id) {
                const runningTask = tasks.find(t => t.id === runningTaskKey);
                if (runningTask) {
                    showToastError2()
                    return
                }
            } else {



                if (isTimerRunning) {
                    setIsLoading(true)

       
                    const endTime = new Date();
                    const totalTime = Math.round((endTime.getTime() - startTime!.getTime()) / 60000);
             
                    const numbersColumnIndex = task.column_values.findIndex(c => c.id === "numbers2");
                    if (numbersColumnIndex === -1) {
                        throw new Error("Numbers column not found.");
                    }






                    const rawUsername = localStorage.getItem('fullName_rom');
                    const username = rawUsername ? rawUsername.replace(/^"|"$/g, '') : 'Unknown';
                    const startTimeStr = startTime ? `${startTime.getHours()}:${startTime.getMinutes().toString().padStart(2, '0')}` : 'Start Time';
                    const endTimeStr = `${endTime.getHours()}:${endTime.getMinutes().toString().padStart(2, '0')}`;
                    const msg = `${username} worked on this task for ${totalTime} minutes (from ${startTimeStr} to ${endTimeStr})`;
                    const rawUserID = localStorage.getItem('userID_rom');
                    const token = rawUserID ? rawUserID.replace(/^"|"$/g, '') : 'Unknown';
                    const currentTime = new Date();
                    const formattedDate = taskService.formatDate(currentTime);
                    const endTaskDataProps = {
                        token: token,
                        boardName: task.name,
                        itemID: task.id,
                        msg: msg,
                        // boardID: "1349699010",
                        username: username,
                        taskName: task.name,
                        taskID: task.id,
                        from: startTimeStr,
                        to: endTimeStr,
                        amount: totalTime,
                        date: formattedDate,
                    }



                    await taskService.endTask(endTaskDataProps)
                    showToast(totalTime);
                    localStorage.removeItem(timerKey)

                } else {
                    const currentTime = new Date();
                    setStartTime(currentTime);
                    setIsTimerRunning(true);
                    localStorage.setItem(timerKey, currentTime.toISOString());
                    const rawUserID = localStorage.getItem('userID_rom');
                    const token = rawUserID ? rawUserID.replace(/^"|"$/g, '') : 'Unknown';
                    const startTaskData = {
                        token: token,
                        boardName: task.name,
                        itemID: task.id
                    }
                    await taskService.startTask(startTaskData)
                }

            }


        } catch (error) {
            console.error("An error occurred in handleStartStop: ", error);
            showToastError();
            setIsLoading(false)
        } finally {
            if (isTimerRunning) {
                setTimer(0);
                setIsTimerRunning(false);
                setStartTime(null);
            }
            setIsLoading(false)

        }

    };





    useEffect(() => {

        let interval: number | undefined;

        if (isTimerRunning) {
            interval = window.setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer >= 28800) {
                        setIsTimerRunning(false);
                        setStartTime(null);
                        localStorage.removeItem(timerKey);
                        if (interval !== undefined) clearInterval(interval);
                        return 0;
                    }
                    return prevTimer + 1;
                });
            }, 1000);
        }

        return () => {
            if (interval !== undefined) clearInterval(interval);
        };
    }, [isTimerRunning, timerKey]);




    useEffect(() => {
        const savedStartTime = localStorage.getItem(timerKey);
        if (savedStartTime) {
            const savedTime = new Date(savedStartTime);
            const currentTime = new Date();
            const timeDifference = Math.max(currentTime.getTime() - savedTime.getTime(), 0);
            const secondsElapsed = Math.floor(timeDifference / 1000);

            setTimer(secondsElapsed);
            setIsTimerRunning(true);
            setStartTime(savedTime);
        }
    }, [timerKey]);


    function showToast(minutes: number) {

        setToastProps({
            variant: 'success',
            title: t("toast12Title"),
            description: `${t("toast12Description")} ${minutes} ${t("toast12Description2")}`,

        });
    }

    function showToastError() {

        setToastProps({
            variant: 'destructive',
            title: t("toast13Title"),
            description: t("toast13Description")
        });
    }

    function showToastError2() {

        setToastProps({
            variant: 'destructive',
            title: t("toast14Title"),
            description: t("toast14Description")
        });
    }




    return (
        <div className={` border p-4 rounded shadow-lg m-2 relative transition duration-500 ease-in-out ${isTimerRunning ? 'border-red-500 border-animation' : ''}`}>

            <p className=" mb-2"><strong>{t("taskCardBoardName")}:</strong> {task.name}</p>
            <p className=" mb-2">
                <strong>{t("taskCardBoardNumber")}: </strong>{
                    task.column_values.find(column => column.id === 'text')?.text || 'No text found'
                }
            </p>            <div className="flex flex-col">

            </div>
            <div className='flex justify-between mt-2 items-center '>

                {localStorage.getItem("userType_rom")?.replace(/^"|"$/g, '') === "1" ?
                    (

                        <Button
                            className='mb-2'
                            onClick={() => handleStartStop(task)}
                        >
                            {isTimerRunning ? t("stop") : t("start")}
                        </Button>
                    ) : (
                        ""
                    )
                }
                {timer !== 0 && <TimerDisplay whereToShow='tasks-card' timer={timer} />}

                <TaskDescription task={task} />
            </div>
            {isLoading && <Loader />}
            {toastProps && (
                <ToastComponent
                    variant={toastProps.variant}
                    title={toastProps.title}
                    description={toastProps.description}
                />
            )}


        </div >
    );
}
