import React from 'react'


import { useTranslation } from 'react-i18next';
interface ValidationModalProps {
  msg: string;
  onClose: () => void; // Assuming onClose is a function that takes no arguments and returns nothing
}
export function ValidationModal({ msg, onClose }: ValidationModalProps) {

  const [t, i18n] = useTranslation("global")
  const isRtl = i18n.language === "he";
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 transition-opacity ease-out duration-300" dir={isRtl ? "rtl" : "ltr"}>
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-xs md:max-w-sm relative transition-transform ease-out duration-300 opacity-90 translate-y-[-10px]">
        <button
          className="absolute w-6 h-6 top-1 right-1 bg-transparent border-none text-gray-500 cursor-pointer transition-colors ease-out duration-300 outline-none p-0 line-height-1"
          onClick={onClose}
        >
          &times; {/* This is a multiplication symbol that looks like a close icon. */}
        </button>
        <p className="text-lg leading-relaxed mb-5 text-gray-800 font-sans">{msg}</p>
      </div>
    </div>
  )
}
