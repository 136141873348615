import React, { useState, useEffect } from 'react';
import { parse, format, isValid } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { AllUsersSelect } from '../cmps/AllUsersSelect';
import { DatePicker } from '../cmps/pickers/DatePicker';
import { DatePicker2 } from '../cmps/pickers/DatePicker2';
import { ReportsTable } from '../cmps/tables/ReportsTable';
import { useDispatch } from 'react-redux';
import { useToast } from "../components/ui/use-toast";
import { FillFieldsModal } from '../cmps/modals/FillFieldsModal';
import { SearchBoardReport } from '../cmps/SearchBoardReport';
import { taskService } from '../services/task.service';
import { useTranslation } from 'react-i18next';
import transition from "../transition";
import { Icon } from '../cmps/loadersAndIcons/Icon';
import * as XLSX from 'xlsx';


interface Report {
    date: string;
    employeeName: string;
    numOfLogs: number;
    totalHours: number;
}
function Reports() {
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [reports, setReports] = useState(null);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
    const [showFillFieldsModal, setShowFillFieldsModal] = useState<boolean | undefined>(false);
    const [isSearchButtonClicked, setIsSearchButtonClicked] = useState<boolean | undefined>(false);
    // const [is, setIsSearchButtonClicked] = useState<boolean | undefined>(false);
    const rawUserType = localStorage.getItem('userType_rom');

    const userType = rawUserType ? rawUserType.replace(/^"|"$/g, '') : 'Unknown';

    const rawUserID = localStorage.getItem('userID_rom');

    const userID = rawUserID ? rawUserID.replace(/^"|"$/g, '') : 'Unknown';
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { toast } = useToast()

    useEffect(() => {
        setIsSearchButtonClicked(false)

        const searchParams = new URLSearchParams(location.search);
        const start = searchParams.get('startDate');
        const end = searchParams.get('endDate');
        const user = searchParams.get('employeeID');

        if (start && end && user) {
            const parsedStart = parse(start, "dd/MM/yyyy", new Date());
            const parsedEnd = parse(end, "dd/MM/yyyy", new Date());
            if (isValid(parsedStart) && isValid(parsedEnd)) {
                setStartDate(parsedStart);
                setEndDate(parsedEnd);
                setSelectedUser(user);
                const userDetails = {
                    startDate: format(parsedStart, "dd/MM/yyyy"),
                    endDate: format(parsedEnd, "dd/MM/yyyy"),
                    employeeID: user
                };


                const fetchReports = async () => {
                    const res = await taskService.getReports(userDetails);
                    console.log("res", res)
                    setReports(res);
                };

                fetchReports();
            }
        }
    }, [dispatch, location.search]);

    const handleStartDateChange = (dateString: string) => {
        const newDate = parse(dateString, "dd/MM/yyyy", new Date());
        if (isValid(newDate)) {
            setStartDate(newDate);
        }
    };

    const handleEndDateChange = (dateString: string) => {
        const newDate = parse(dateString, "dd/MM/yyyy", new Date());
        if (isValid(newDate)) {
            setEndDate(newDate);
        }
    };

    async function performSearch(startDate, endDate, employeeID) {
        const formattedStart = format(startDate, "dd/MM/yyyy");
        const formattedEnd = format(endDate, "dd/MM/yyyy");
        setIsSearchButtonClicked(true);

        navigate(`?startDate=${formattedStart}&endDate=${formattedEnd}&employeeID=${employeeID}`);

        const userDetails = {
            startDate: formattedStart,
            endDate: formattedEnd,
            employeeID
        };

        const res = await taskService.getReports(userDetails);
        setReports(res);
        setIsSearchButtonClicked(false);
    }

    const handleSearch = async () => {
        if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
            const employeeID = userType === "2" ? selectedUser : userID;

            await performSearch(startDate, endDate, employeeID);
        } else {
            showToastError();
        }
    };
    // const handleSearch = async () => {


    //     if (userType === "2") {


    //         if (startDate && endDate && selectedUser && isValid(startDate) && isValid(endDate)) {
    //             const formattedStart = format(startDate, "dd/MM/yyyy");
    //             const formattedEnd = format(endDate, "dd/MM/yyyy");
    //             setIsSearchButtonClicked(true)
    //             navigate(`?startDate=${formattedStart}&endDate=${formattedEnd}&employeeID=${selectedUser}`);

    //             const userDetails = {
    //                 startDate: formattedStart,
    //                 endDate: formattedEnd,
    //                 employeeID: selectedUser
    //             };
    //             const res = await taskService.getReports(userDetails);
    //             setReports(res)
    //             setIsSearchButtonClicked(false)
    //         } else {
    //             showToastError()
    //         }
    //     } else {
    //         if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
    //             const formattedStart = format(startDate, "dd/MM/yyyy");
    //             const formattedEnd = format(endDate, "dd/MM/yyyy");
    //             setIsSearchButtonClicked(true)
    //             navigate(`?startDate=${formattedStart}&endDate=${formattedEnd}&employeeID=${userID}`);

    //             const userDetails = {
    //                 startDate: formattedStart,
    //                 endDate: formattedEnd,
    //                 employeeID: userID
    //             };
    //             const res = await taskService.getReports(userDetails);
    //             setReports(res)
    //             setIsSearchButtonClicked(false)
    //         } else {
    //             showToastError()
    //         }
    //     }
    // };
    const [t, i18n] = useTranslation("global");

    function showToastError() {
        toast({
            variant: "destructive",
            title: t("toast11Title"),
            description: t("toast11Description"),
        })
    }
    const exportToExcel = () => {
        if (reports) {
            const dataForExport = reports.map(({ date, employeeName, numOfLogs, totalHours }: Report) => ({
                "תאריך": date,
                "שם עובד": employeeName,
                "לוגים": numOfLogs,
                "שעות": totalHours
            }));

            const ws = XLSX.utils.json_to_sheet(dataForExport);

            ws['!cols'] = [
                { wch: 15 }, // 'תאריך' column width
                { wch: 30 }, // 'שם עובד' column width
                { wch: 10 }, // 'לוגים' column width
                { wch: 10 }  // 'שעות' column width
            ];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Reports");

            XLSX.writeFile(wb, "reports.xlsx");
        }
    };


    return (
        <div>
            <div className="flex flex-col sm:flex-row gap-6 items-center justify-center">
                {/* {reports && <Icon name='Download' className="cursor-pointer" onClick={exportToExcel} />} */}
                <DatePicker selectedDate={new URLSearchParams(location.search).get('startDate')} onDateChange={handleStartDateChange} />
                <DatePicker2 selectedDate={new URLSearchParams(location.search).get('endDate')} onDateChange={handleEndDateChange} />
                <div className='flex  gap-6'>
                    {userType === "2" && <AllUsersSelect selectedUser={new URLSearchParams(location.search).get('employeeID')} onUserChange={setSelectedUser} />}
                    <Button onClick={handleSearch}>{t("reportSearchButton")}</Button>
                </div>
                {userType === "2" && <SearchBoardReport />}
            </div>
            <ReportsTable
                reports={reports}
                isSearchButtonClicked={isSearchButtonClicked}
                startDate={startDate ? format(startDate, "dd/MM/yyyy") : ""}
                endDate={endDate ? format(endDate, "dd/MM/yyyy") : ""}
                selectedUser={selectedUser || ""}
            />
            {showFillFieldsModal && <FillFieldsModal />}
        </div>

    );
}


export default transition(Reports)