import * as React from "react"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"
import { useTranslation } from 'react-i18next';

interface IsActiveSelectProps {
    isActive: string | null,
    setUserTypeChanged: React.Dispatch<React.SetStateAction<string | null>>
}
export function UserTypeSelect({ isActive, setUserTypeChanged }: IsActiveSelectProps) {
    const [t, i18n] = useTranslation("global");
    return (
        <Select onValueChange={(value) => {
            setUserTypeChanged(value);
        }}>            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t("UserTypeSelectMainTitle")} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="1">{t("userTypeSelectOption1")}</SelectItem>
                    <SelectItem value="2">{t("userTypeSelectOption2")}</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
