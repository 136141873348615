import * as React from "react"
import { useEffect, useState } from "react";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"
import { useTranslation } from 'react-i18next';
import { taskService } from "../services/task.service";

interface AllUsersSelectProps {
    onUserChange: (value: string) => void;
    selectedUser?: string | null;
}

export function AllUsersSelect({ onUserChange, selectedUser }: AllUsersSelectProps) {
    const [selectedValue, setSelectedValue] = React.useState<string>(selectedUser ? selectedUser : "");
    const [users, setUsers] = useState<{ userID: string; firstName: string; lastName: string; fullName: string }[]>([]); // Define the state to store users

    const handleSelectItem = (value: string) => {
        setSelectedValue(value);
        onUserChange(value);
    };


    useEffect(() => {
        const fetchUserData = async () => {
            const usersData = await taskService.getAllUsers();
            setUsers(usersData);
        };

        fetchUserData();
    }, []);
    const { t, i18n } = useTranslation('global');



    return (
        <Select value={selectedValue} onValueChange={handleSelectItem}>
            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t("allUsersSelectPlaceholder")} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectLabel>
                        {t("allUsersSelectLable")}
                    </SelectLabel>
                    {users.map((user) => (
                        <SelectItem key={user.userID} value={user.userID}>{user.fullName}</SelectItem>
                    ))}
                    <SelectItem value={"0"}>
                        {t("allUsersSelectItem1")}
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select >
    );
}
