import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getFirebaseToken, onMessage } from 'firebase/messaging';
import { taskService } from './task.service';

var firebaseConfig = {
    apiKey: "AIzaSyDH3FbCrltC4iRYstLai1jefUHeu87a4lI",
    authDomain: "mixit-509d0.firebaseapp.com",
    databaseURL: "https://mixit-509d0.firebaseio.com",
    projectId: "mixit-509d0",
    storageBucket: "mixit-509d0.appspot.com",
    messagingSenderId: "531809563525",
    appId: "1:531809563525:web:c652999cb97bd5b9efcaec",
    measurementId: "G-J9KZXXRD13"

    // apiKey: "AIzaSyAOH1Yyc2wcxV0g-qCNjCxvkiKPVkweDgA",
    // authDomain: "fir-romday.firebaseapp.com",
    // projectId: "fir-romday",
    // storageBucket: "fir-romday.appspot.com",
    // messagingSenderId: "157021754005",
    // appId: "1:157021754005:web:5935a743cb021913a7c19c"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
// interface FCMTokenData {
//     fcmToken: string,
//     token: string
// }
export const requestFirebaseToken = () => {
    return getFirebaseToken(messaging, { vapidKey: 'BNVs6BVGH_uYlizOJg-LQ4X7x0Yfw34bWO9KPAbPrDE6Nvo_E6JEU0YGCe8qX8ZD9miyVNvJ3Bo5A8Crqv4CA1Y' }).then((currentToken) => {
        if (currentToken) {
    
            const rawUserID = localStorage.getItem('userID_rom');
            const userID = rawUserID ? rawUserID.replace(/^"|"$/g, '') : 'Unknown';
            const data = {
                fcmToken: currentToken,
                token: userID,
            }
            taskService.updateFCMToken(data)
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};