import React, { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { getusers } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';
import { useTranslation } from 'react-i18next';
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Icon } from '../loadersAndIcons/Icon';
import { Loader } from '../loadersAndIcons/Loader';

export type ViewRecord = {
    endDateTime: string;
    firstName: string;
    lastName: string;
    startDateTime: string;
    userID: string;
    fullName: string;

};
export interface BoardReport {
    endDateTime: string;
    firstName: string;
    lastName: string;
    startDateTime: string;
    userID: string;
    fullName: string;
    totalHours: string;
}
interface BoardReportsTableProps {
    boardReports: BoardReport[];
    boardNumber: string;
    isLoading: boolean;
    // Using the interface as the type for boardReports
}
export function BoardReportsTable({ boardReports, boardNumber, isLoading }: BoardReportsTableProps) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [t, i18n] = useTranslation("global");

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();




    const columns: ColumnDef<BoardReport>[] = [
        {
            accessorKey: "fullName",
            header: t("boardReportsTableColumn1"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "startDateTime",
            header: t("boardReportsTableColumn2"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "endDateTime",
            header: t("boardReportsTableColumn3"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "totalHours",
            header: t("boardReportsTableColumn4"),
            cell: info => info.getValue(),
        },

    ];



    const table = useReactTable({
        data: boardReports || [], // Use an empty array as a fallback
        columns,
        state: { sorting, globalFilter },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        const fetchUserData = async () => {
            dispatch(getusers())

        };

        fetchUserData();
    }, []);

    if (isLoading) {
        return (
            <div className="custom-loader"></div>
        );
    }
    // if (boardReports.length === 0 && boardNumber === "") {
    //     return (
    //         <div className="flex flex-col items-center justify-center mt-36">
    //             <div className='flex gap-2'>

    //                 <h1>{t("reportsTitle2WhenThereAreNoReports")}</h1>
    //                 <Icon name='MousePointerSquare' />
    //             </div>
    //         </div>
    //     );
    // }

    // if (boardReports.length === 0 && boardNumber !== "") {
    //     return (
    //         <div className="flex flex-col items-center justify-center mt-36">
    //             <div className='flex gap-2'>

    //                 <h1>{t("reportsTitle3WhenThereAreNoReports")}</h1>
    //                 <Icon name='AlertTriangle' />
    //             </div>
    //         </div>
    //     );
    // }
    const isRtl = i18n.language === "he";

    return (
        <div className="w-full" dir={isRtl ? "rtl" : "ltr"}>

            <div className="flex items-center py-4">

            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="text-center">
                                    {t("noResults")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getRowModel().rows.length} {t("of")} {boardReports.length} {t("records")}.
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {t("prevButton")}
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {t("nextButton")}

                    </Button>
                </div>
            </div>
        </div>
    );
}
