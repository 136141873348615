import { useState } from "react";
import { Button } from "../../components/ui/button"
import { useToast } from "../../components/ui/use-toast"
import { useTranslation } from 'react-i18next';

import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { addUser } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { UserTypeSelect } from "../UserTypeSelect";
import { DepartmentSelect } from "../DepartmentSelect";

export function AddUserModal() {
    const { toast } = useToast()

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [externalID, setExternalID] = useState('');
    const [externalID2, setExternalID2] = useState('');
    const [password, setPassword] = useState('');
    const [confirPassword, setConfirmPassword] = useState('');
    const [userName, setUserName] = useState(''); // username to login
    const [userType, setUserType] = useState<string | null>(null);
    const [departmentType, setDepartmentType] = useState<string | null>(null);

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)
    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const handleExternalIDChange = (event: React.ChangeEvent<HTMLInputElement>) => setExternalID(event.target.value);
    const handleExternalID2Change = (event: React.ChangeEvent<HTMLInputElement>) => setExternalID2(event.target.value);
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);
    const handleUserNameToLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => setUserName(event.target.value);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (name === '' || username === '' || externalID === '' || password === '' || confirPassword === '' || userName === "" || !userType || !departmentType) {
            showFillFields()
            return
        }

        if (password !== confirPassword) {
            dontMatch()
            return
        }

        const userData = {
            firstName: name,
            lastName: username,
            userName: userName,
            externalID: externalID,
            externalID2: externalID2,
            userTypeID: userType || "1",
            password: password,
            departmentID: departmentType
        };

        try {
            const res = await dispatch(addUser(userData, showToast, showExternalID2Error, showToastError, showToastError2, closeDialog, showUserNameExistError));

            // const res = await dispatch(addUser(userData))
            // console.log("res", res)
            // closeDialog();
            // showToast(username)
        } catch (error) {
            console.error('Error adding user:', error);
            showToastError()
        }
    };
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const [t, i18n] = useTranslation("global");
    const isRtl = i18n.language === "he";

    function showToast(userName: string) {
        toast({
            title: t("toastFiveTitle"),
            description: `${t("toastFiveDescription")} ${userName} `,
        })
    }
    function showToastError() {
        toast({
            variant: "destructive",
            title: t("toastSixTitle"),
            description: t("toastSixDescription"),
        })
    }
    function showToastError2() {
        toast({
            variant: "destructive",
            title: t("toastSevenTitle"),
            description: t("toastSevenDescription"),
        })
    }
    function dontMatch() {
        toast({
            variant: "destructive",
            title: t("toastFourTitle"),
            description: t("toastFourDescription"),
        })
    }
    function showFillFields() {
        toast({
            variant: "destructive",
            title: t("toast20Title"),
            description: t("toast20Description"),
        })
    }
    function showUserNameExistError() {
        toast({
            variant: "destructive",
            title: t("toast20Title"),
            description: t("toast40Description"),
        })
    }
    function showExternalID2Error() {
        toast({
            variant: "destructive",
            title: t("toast100Title"),
            description: t("toast100Description"),
        })
    }
    return (
        <div dir={isRtl ? "rtl" : "ltr"}>


            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} >
                <DialogTrigger asChild>
                    <Button onClick={() => setIsDialogOpen(true)}>{t("addUserModalTitle")}</Button>
                </DialogTrigger>
                <div dir={isRtl ? "rtl" : "ltr"}>
                    <DialogContent className="sm:max-w-[425px]">
                        <form onSubmit={handleSubmit} dir={isRtl ? "rtl" : "ltr"}> {/* Add this line */}

                            <DialogHeader>
                                <DialogTitle>{t("addUserModalTitle")}</DialogTitle>
                                {/* <DialogDescription>
                            Add user to your company here. Click add when you're done.
                        </DialogDescription> */}
                            </DialogHeader>
                            <div className="grid gap-4 py-4" dir={isRtl ? "rtl" : "ltr"}>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="name" className="text-right text-red-600">
                                        {t("addUserFirstName")}:*
                                    </Label>
                                    <Input
                                        id="name"
                                        placeholder={t("addUserFirstName")}
                                        defaultValue={name}
                                        className="col-span-3"
                                        onChange={handleNameChange} // Attach the handler here
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="username" className="text-right text-red-600">
                                        {t("addUserLastName")}:*
                                    </Label>
                                    <Input
                                        id="username"
                                        placeholder={t("addUserLastName")}

                                        defaultValue={username}
                                        className="col-span-3"
                                        onChange={handleUsernameChange}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="userName" className="text-right text-red-600">
                                        {t("addUserUserName")}:*
                                    </Label>
                                    <Input
                                        id="username"
                                        placeholder={t("addUserUserName")}

                                        defaultValue={userName}
                                        className="col-span-3"
                                        onChange={handleUserNameToLoginChange}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="username" className="text-right text-red-600">
                                        {t("addUserExternalID")}:*
                                    </Label>
                                    <Input
                                        id="username"
                                        placeholder={t("addUserExternalID")}
                                        defaultValue={externalID}
                                        className="col-span-3"
                                        onChange={handleExternalIDChange}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="username" className="text-right text-red-600">
                                        {t("addUserExternalID2")}:*
                                    </Label>
                                    <Input
                                        id="username"
                                        placeholder={t("addUserExternalID2")}
                                        defaultValue={externalID2}
                                        className="col-span-3"
                                        onChange={handleExternalID2Change}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="password" className="text-right text-red-600">
                                        {t("EditUserModalPassword")}:*

                                    </Label>
                                    <Input
                                        id="password"
                                        placeholder={t("EditUserModalPassword")}
                                        type="password"

                                        defaultValue={password}
                                        className="col-span-3"
                                        onChange={handlePasswordChange}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="password" className="text-right text-red-600">
                                        {t("EditUserModalConfirmPassword")}:*
                                    </Label>
                                    <Input
                                        id="password"
                                        placeholder={t("EditUserModalConfirmPassword")}
                                        type="password"

                                        defaultValue={confirPassword}
                                        className="col-span-3"
                                        onChange={handleConfirmPasswordChange}
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="password" className="text-right text-red-600" >
                                        {t("EditUserModalUserType")}:*
                                    </Label>
                                    <UserTypeSelect setUserTypeChanged={setUserType} />
                                    {/* <DepartmentSelect setDepartmentTypeChanged={setDepartmentType} /> */}

                                </div>
                                <div className="grid grid-cols-4 items-center gap-4 ">
                                    <Label htmlFor="password" className="text-right text-red-600" >
                                        {t("departmentSelectTitle")}:*
                                    </Label>
                                    {/* <UserTypeSelect setUserTypeChanged={setUserType} /> */}
                                    <DepartmentSelect setDepartmentTypeChanged={setDepartmentType} />

                                </div>
                            </div>
                            <DialogFooter>
                                <Button type="submit">{t("addUserModalButton")}</Button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </div>

            </Dialog>
        </div>
    )
}
