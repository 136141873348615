import { Moon, Sun } from "lucide-react"
import { useTranslation } from 'react-i18next';

import { Button } from "../components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { useTheme } from "../cmps/ThemeProvider"
type ModeToggleProps = {
    closeMobileNavBar: () => void;
};
type Theme = 'light' | 'dark' | 'system';
// export function ModeToggle({ closeMobileNavBar }: ModeToggleProps) {
export function ModeToggle() {
    const [t, i18n] = useTranslation("global");
    const { setTheme } = useTheme()




    function changeThemeAndCloseMobileNavBar(theme: Theme) {
        setTheme(theme)
        // closeMobileNavBar()
    }


    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="icon">
                    <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                    <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => changeThemeAndCloseMobileNavBar("light")}>
                    {t("ThemeProviderOption1")}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => changeThemeAndCloseMobileNavBar("dark")}>
                    {t("ThemeProviderOption2")}

                </DropdownMenuItem>
                {/* <DropdownMenuItem onClick={() => changeThemeAndCloseMobileNavBar("system")}>
                    {t("ThemeProviderOption3")}

                </DropdownMenuItem> */}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
