import React, { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { getusers } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';
import { useTranslation } from 'react-i18next';

import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { useToast } from "../../components/ui/use-toast"
import { EditUserModal } from '../modals/EditUserModal';
import { Loader } from '../loadersAndIcons/Loader';
import { DialogDemo } from '../DialogDemo';

// Define the type for the data you are receiving
export type ViewRecord = {
    userID: string;
    firstName: string;
    lastName: string;
    isActive: string;
    userTypeID: string
};

export function UsersManageTable() {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const usersData = useSelector((storeState: RootState) => storeState.taskModule.users);
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const [t, i18n] = useTranslation("global");
    // "userManageColumn1": "First name",
    //     "userManageColumn2": "Last name",
    //         "userManageColumn3": "Active",
    //             "userManageColumn4": "Edit",
    //                 "userManageColumn5": "Delete"

    const columns: ColumnDef<ViewRecord>[] = [


        {
            accessorKey: "firstName",
            header: t("userManageColumn1"),
            cell: info => info.getValue(),
            enableSorting: true,
        },
        {
            accessorKey: "lastName",
            header: t("userManageColumn2"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "phone1",
            header: t("userManageColumn4"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "userTypeID",
            header: t("userManageColumn5"),
            cell: info => info.getValue() === "1" ? "עובד רגיל" : "מנהל",
        },
        {
            accessorKey: "department",
            header: t("userManageColumn70"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "isActive",
            header: t("userManageColumn3"),
            cell: info => info.getValue() === "1" ? "Active" : "Not Active",
        },

        {
            accessorKey: "edit",
            header: t("userManageColumn4"),
            cell: (info) => (
                <EditUserModal
                    firstName={info.row.original.firstName}
                    lastName={info.row.original.lastName}
                    userID={info.row.original.userID}
                    activeStatus={info.row.original.isActive}
                    userTypeStatus={info.row.original.userTypeID}
                    departmentType={info.row.original.departmentID}
                // Add this line
                />),
        },
        {
            accessorKey: "remove",
            header: t("userManageColumn5"),
            cell: (info) => (
                <DialogDemo userID={info.row.original.userID} />

            ),
        },


    ];


    const table = useReactTable({
        data: usersData || [], // Use an empty array as a fallback
        columns,
        state: { sorting, globalFilter },
        onSortingChange: setSorting,
        // Removed the globalFilterFn and instead applied filterData above
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getCoreRowModel(), // Replaced with a custom sort function
        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        const fetchUserData = async () => {
            dispatch(getusers())

        };

        fetchUserData();
    }, []);



    if (usersData.length === 0) return (

        <Loader />

    )

    const isRtl = i18n.language === "he"

    return (
        <div className="w-full" dir={isRtl ? "rtl" : "ltr"}>

            <div className="flex items-center py-4">

            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="text-center">
                                    {t("noResults")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getRowModel().rows.length} {t("of")} {usersData.length} {t("records")}.
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {t("prevButton")}
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {t("nextButton")}

                    </Button>
                </div>
            </div>
        </div>
    );
}
