import { Button } from "../components/ui/button"
import { useTranslation } from 'react-i18next';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../components/ui/tooltip"
import { Icon } from "./loadersAndIcons/Icon"


export function EditedIconToolTip() {
    const [t, i18n] = useTranslation("global")

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button variant="outline">
                        <Icon name='ShieldCheck' color="green" />
                    </Button>

                </TooltipTrigger>
                <TooltipContent>
                    <p>{t("EditIconToolTip")}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
