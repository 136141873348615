import React, { useEffect } from 'react';
import { TimerDisplay } from '../cmps/TimerDisplay';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBossData } from '../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../store/index';
import { Loader } from '../cmps/loadersAndIcons/Loader';
import { useTheme } from '../cmps/ThemeProvider';
import { useTranslation } from 'react-i18next';
import transition from "../transition"


function BossPage() {
    const workingArr = useSelector((storeState: RootState) => storeState.taskModule.workingArr) || [];
    const [t, i18n] = useTranslation("global");

    const notWorkingArr = useSelector((storeState: RootState) => storeState.taskModule.notWorkingArr) || [];
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const { theme } = useTheme();
    useEffect(() => {
        dispatch(fetchBossData())
    }, [dispatch]);



    // const calculateTimeDiff = (startTime?: string) => {
    //     if (!startTime) {
    //         return 0;
    //     }
    //     const start = new Date(startTime).getTime();
    //     const now = Date.now();
    //     return Math.floor((now - start) / 1000);
    // };


    if (!notWorkingArr && !workingArr) {
        return <Loader />
    }



    return (
        <>
            <h1 className=" w-full text-center top-0 mt-4 mb-5 font-bold text-2xl neon-effect">{t("BossPageTitle")}</h1>
            <div className="mx-auto mt-8 flex">

                <aside className={`${theme === "dark" ? "bg-black text-white" : "bg-white text-black"} w-1/4 p-4  border-white border-2`}>
                    <h2 className="font-bold text-2xl mb-4 text-center text-blue-700">{t("BossPageSubTitle2")}</h2>
                    <ul>
                        {notWorkingArr.map((employee, index) => (
                            <li key={employee.userID} className="mb-5 font-bold text-4xl border-b border text-center">
                                {employee.employeeName}
                            </li>
                        ))}
                    </ul>
                </aside>
                <section className={`${theme === "dark" ? "bg-black text-white" : "bg-white text-black"} w-3/4 p-4 flex flex-wrap  border-white border-2`}>
                    <h2 className="font-bold text-2xl mb-4  text-blue-700">{t("BossPageSubTitle1")}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {workingArr.map((employee, index) => (
                            <div key={employee.userID} className={`border p-4 rounded shadow-lg m-2 relative transition duration-500 ease-in-out ${theme === "dark" ? "bg-black text-white" : "bg-white text-black "}`}>
                                <p className="mb-2 font-bold text-xl">{t("BossPageBoardName")}: {employee.boardName}</p>
                                <p className="mb-2 font-bold text-xl">{t("BossPageUserName")}: {employee.employeeName}</p>
                                {/* <TimerDisplay timer={calculateTimeDiff(employee.startDateTime)} whereToShow='boss-data' /> */}
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </>
    );
}

export default transition(BossPage);
