import React from 'react';
import { TaskPreview } from "./TaskPreview";
import { TaskTablePreview } from "../tables/TaskTablePreview"; // Make sure to import this
import { useTheme } from '../ThemeProvider';
import { motion, AnimatePresence } from 'framer-motion'; // Import AnimatePresence

import { useTranslation } from 'react-i18next';
interface Task {
    id: string;
    name: string;
    column_values: {
        id: string;
        text: string;
    }[];
}

interface TaskListProps {
    tasks: Task[];
    viewMode: string;
}

export function TaskList({ tasks, viewMode }: TaskListProps) {
    const [t, i18n] = useTranslation("global")

    const { theme } = useTheme();
    const variants = {
        hidden: {
            opacity: 0,
            scale: 0.95,
            y: 30,
            rotate: -10
        },
        visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                stiffness: 260,
                damping: 20
            }
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            y: -30,
            rotate: 10,
            transition: {
                duration: 0.3
            }
        }
    };

    return (
        <div className='mt-10'>
            <AnimatePresence>

                {viewMode === 'grid' ? (
                    <motion.section
                        layout
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
                    >
                        {tasks?.map(task => (
                            <motion.div key={task.id} variants={variants} initial="hidden" animate="visible" exit="exit">
                                <TaskPreview task={task} tasks={tasks} />
                            </motion.div>
                        ))}
                    </motion.section>
                ) : (
                    <table className={`min-w-full divide-y ${theme === "dark" ? "divide-black" : "divide-gray-200"}`}>
                        <thead className={`${theme === "dark" ? "bg-black" : "bg-gray-50"}  h-100`}>
                            <tr className='h-10'>
                                <th className={`text-center text-xl font-medium ${theme === "dark" ? "text-white" : "text-black"}  tracking-wider`}>
                                    {t("taskCardBoardName")}
                                </th>
                                <th className={`text-center text-xl font-medium ${theme === "dark" ? "text-white" : "text-black"}  tracking-wider`}>
                                    {t("taskCardBoardNumber")}
                                </th>
                                {localStorage.getItem("userType_rom")?.replace(/^"|"$/g, '') === "1" && <th className={`text-center text-xs font-medium ${theme === "dark" ? "text-white " : "text-black "} uppercase tracking-wider`}>
                                    {t("taskCardAction")}
                                </th>}
                                <th className={`text-center text-xl font-medium ${theme === "dark" ? "text-white" : "text-black"}  tracking-wider`}>
                                    {t("taskCardDetails")}
                                </th>
                                {localStorage.getItem("userType_rom")?.replace(/^"|"$/g, '') === "1" && <th className={`text-center text-xs font-medium ${theme === "dark" ? "text-white " : "text-black "} uppercase tracking-wider`}>
                                    {t("taskCardTimer")}
                                </th>}
                            </tr>
                        </thead>
                        <tbody className={`${theme === "dark" ? "bg-black divide-y divide-black" : "bg-white divide-y divide-gray-200"}`}>
                            {tasks.map(task => <TaskTablePreview key={task.id} task={task} tasks={tasks} />)}
                        </tbody>
                    </table>
                )}
            </AnimatePresence>
        </div>
    );
}