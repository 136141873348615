import { storageService } from "../services/storage.service";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../components/ui/alert-dialog"
import { useNavigate } from "react-router-dom";
import { TooltipDemo } from "./TooltibDemo";
import { Button } from "../components/ui/button";
import { taskService } from "../services/task.service";
import { useTranslation } from 'react-i18next';

export function AlertDialogDemo() {
    const [t, i18n] = useTranslation("global");

    const navigate = useNavigate()


    async function handleLogout() {

        const userID = localStorage.getItem('userID_rom');
        if (userID) {
            await taskService.logout(userID);
            storageService.remove('fullName_rom', false);
            storageService.remove('date_rom', false);
            storageService.remove('userID_rom', false);

            navigate('/');
        } else {
            navigate('/');
            console.error('No userID found in localStorage');
        }
    };



    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button className="w-14">  <TooltipDemo IconName={'LogOut'} titleName={"Logout"} /></Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{t("LogoutModalTitle")}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {t("LogoutModalDescription")}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{t("LogoutModalCancel")}</AlertDialogCancel>
                    <AlertDialogAction onClick={handleLogout}>{t("LogoutModalLogout")}</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
