import React, { useEffect, useState } from 'react';
import { ModeToggle } from "./ModeToggle";
import { useTranslation } from 'react-i18next';
import { AlertDialogDemo } from "./AlertDialogDemo";
import { MobileNavBar } from "./MobileNavBar";
import { useTheme } from '../cmps/ThemeProvider';
import { storageService } from "../services/storage.service";
import { LanguageSelect } from "./LanguageSelect";
import { NavigationMenuDemo } from "./NavigationMenuDemo";

const useResponsiveLayout = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMobile;
};

export function AppHeader() {
    const { t, i18n } = useTranslation("global"); // Correctly destructure i18n here

    const { theme } = useTheme();
    const isMobile = useResponsiveLayout();

    const setLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
        storageService.store('language_rom', language);
    };

    return (
        <header className="text-white md:p-4 py-4 px-1 sticky top-0 z-10 bg-black" style={{ boxShadow: '0 2px 10px rgba(255, 255, 255, 0.25)' }}>
            {!isMobile ? (
                <div className="container mx-auto flex justify-evenly items-center">


                    <NavigationMenuDemo />
                    <div className="flex items-center gap-1">
                        <h1 className={`text-3xl font-bold `}>
                            {t("appHeaderLogoWord1")}
                        </h1>
                        <img src="https://online.zmnsoft.net/rom/mondayTasks/images/rom-logo.png" alt="logo" className="w-10 h-10 rounded-full" />
                        <h1 className={`text-3xl font-bold`}>
                            {t("appHeaderLogoWord2")}
                        </h1>
                    </div>
                    <div className="flex gap-1 items-center justify-center">
                        <LanguageSelect setLanguage={setLanguage} />
                    </div>
                    <div className="flex gap-1 items-center justify-center">
                        <ModeToggle />
                        <AlertDialogDemo />
                    </div>


                </div>
            ) : (
                <div className='flex items-center justify-between ml-1 mr-1'>
                    <div className="flex items-center gap-1">
                        <h1 className={`text-3xl font-bold `}>
                            {t("appHeaderLogoWord")}
                        </h1>
                        <img src="https://online.zmnsoft.net/rom/mondayTasks/images/rom-logo.png" alt="logo" className="w-10 h-10 rounded-full" />

                    </div>
                    <MobileNavBar />
                </div>
            )}
        </header>
    );
}
