import { UsersManageTable } from '../cmps/tables/UsersManageTable';
import React from 'react';
import { AddUserModal } from '../cmps/modals/AddUserModal';
import transition from "../transition"

function UsersManage() {



    return (
        <>
            <AddUserModal />
            <UsersManageTable />
        </>

    );
}


export default transition(UsersManage)