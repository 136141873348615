import { useState } from "react";
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { useSelector } from 'react-redux';
import { RootState } from '../store/index';
import { Label } from "../components/ui/label"
import {
    Sheet,
    SheetContent,

    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../components/ui/sheet"
import { useTranslation } from 'react-i18next';
import { taskService } from "../services/task.service";
import { BoardReportsTable } from "./tables/BoardReportsTable";
import { Task, ColumnValue } from "./task/TaskPreview";
import { BoardReport } from "./tables/BoardReportsTable";

export function SearchBoardReport() {
    const tasks = useSelector((storeState: RootState) => storeState.taskModule.tasks);
    const [t, i18n] = useTranslation("global");
    const [boardNumber, setBoardNumber] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAnswerClicked, setIsAnswerClicked] = useState(false);


    const [suggestions, setSuggestions] = useState<Task[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setBoardNumber(value);
        setIsAnswerClicked(false)

        if (value.length > 0) {
            const filteredSuggestions = tasks ? tasks.filter(task =>
                task.name.toLowerCase().includes(value.toLowerCase())
            ) : [];

            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };
    const handleSuggestionClick = (name: string) => {
        setBoardNumber(name);
        setIsAnswerClicked(true)
        setSuggestions([]);
    };

    const fetchUserData = async () => {

        if (boardNumber === '') return
        try {

            setIsLoading(true);
            const res = await taskService.searchReportsByBoardNumber(boardNumber);
            setData(res)
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);

            console.log("err", err)
        }
    };
    const calculateTotalHours = (data: BoardReport[]) => {
        return data.reduce((total, currentItem) => {
            return total + +currentItem.totalHours;
        }, 0);
    };

    const isRtl = i18n.language === 'he';

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="outline">{t("searchByBoardNumberButton")}</Button>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>
                        {t("searchByBoardNumberTitle")}
                    </SheetTitle>

                </SheetHeader>
                <div className="grid gap-4 py-4" dir={isRtl ? "rtl" : "ltr"}>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="">
                            {t("searchByBoardNumberInput")}
                        </Label>
                        <div className="col-span-3 relative">
                            <Input
                                id="boardNumber"
                                className="block w-full pl-4 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                placeholder="V22030609"
                                value={boardNumber}
                                onChange={handleInputChange}
                            />
                            {suggestions?.length > 0 ? (
                                <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {suggestions.map((task, index) => (
                                        <li
                                            key={index}
                                            className="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                                            onClick={() => handleSuggestionClick(task.name)}
                                        >
                                            {task.name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                !isAnswerClicked && boardNumber && <div className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md p-2 text-base text-gray-500 text-center">{t("noResults")}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div dir={isRtl ? "rtl" : "ltr"}>

                    <Button onClick={fetchUserData}>
                        {t("searchByBoardNumberSearchButton")}
                    </Button>
                </div>
                <BoardReportsTable boardNumber={boardNumber} isLoading={isLoading} boardReports={data} />
                {data.length > 0 && <p dir={isRtl ? "rtl" : "ltr"} ><strong> {t("searchByBoardNumberResult")}: </strong>{calculateTotalHours(data)}</p>}

            </SheetContent>
        </Sheet >
    )
}
