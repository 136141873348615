import React, { useEffect } from 'react'
import { parse } from 'date-fns';
import { Button } from "../components/ui/button"
import { AllUsersSelect } from '../cmps/AllUsersSelect'
import { DatePicker } from '../cmps/pickers/DatePicker'
import { DatePicker2 } from '../cmps/pickers/DatePicker2'
import { format } from 'date-fns';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { setReportDetails } from '../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../store/index';
import { ReportDetailsTable } from '../cmps/tables/ReportDetailsTable';
import { useLocation } from 'react-router-dom';
import transition from "../transition"
import { Loader } from '../cmps/loadersAndIcons/Loader';

function MainReport() {
    const reports = useSelector((storeState: RootState) => storeState.taskModule.reportDetails);

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();


    const location = useLocation();



    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const start = searchParams.get('startDate');
        const end = searchParams.get('endDate');
        const user = searchParams.get('employeeID');



        if (start && end && user) {
            const userDetails = {
                employeeID: user,
                startDate: start,
                endDate: end,
            };

            dispatch(setReportDetails(userDetails));
        }
    }, []);

    if (!reports) {
        return <Loader />
    }

    return (
        <div>

            <ReportDetailsTable reports={reports} />

        </div>



    );
}


export default transition(MainReport)