import { Skeleton } from "../../components/ui/skeleton"
import { useTheme } from '../../cmps/ThemeProvider';
import { useTranslation } from 'react-i18next';

export function TaskSkeleton() {
    const { theme } = useTheme();

    const [t, i18n] = useTranslation("global")
    const isRtl = i18n.language === "he";
    return (
        <>
            <h1 className=" w-full text-center top-0 mt-4 font-bold text-2xl neon-effect mb-6">{t("skeletonTaskTitle")}</h1>
            <div className="flex flex-col sm:flex-row justify-between mb-5">
                <Skeleton className={`h-10 w-[359px] ${theme === "dark" ? "bg-zinc-700" : "bg-gray-100"} `} />
                <div className="flex gap-3 mr-14 mt-4 sm:mt-0 ">

                    <Skeleton className={`h-14 w-20 ${theme === "dark" ? "bg-zinc-700" : "bg-gray-100"} `} />
                    <Skeleton className={`h-14 w-20 ${theme === "dark" ? "bg-zinc-700" : "bg-gray-100"} `} />
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {Array.from({ length: 44 }).map((_, index) => (
                    <div key={index} className="space-y-2 ">
                        <Skeleton className="h-[178px] w-[359px] flex flex-col justify-evenly p-4">
                            <div className="flex flex-col gap-3">

                                <Skeleton className={`h-4 w-[250px] ${theme === "dark" ? "bg-zinc-700" : "bg-white"} `} />
                                <Skeleton className={`h-4 w-[200px] ${theme === "dark" ? "bg-zinc-700" : "bg-white"} `} />
                            </div>
                            <Skeleton className={`h-10 w-20 ${theme === "dark" ? "bg-zinc-700" : "bg-white"} `} />
                        </Skeleton>
                    </div>
                ))}
            </div>
        </>
    )
}
