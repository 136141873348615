import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from "./cmps/ThemeProvider"
import global_en from "./translations/en/global.json";
import global_he from "./translations/he/global.json";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next'; // Make sure to import i18next


i18next.init({
    interpolation: { escapeValue: false },  // React aeady does escaping
    lng: "en",
    resources: {
        en: {
            global: global_en
        },

        he: {
            global: global_he
        },
    },

})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>

        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">

            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
