import * as React from "react"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"
import { useTranslation } from 'react-i18next';

interface IsActiveSelectProps {
    // isActive: string | null,
    setDepartmentTypeChanged: React.Dispatch<React.SetStateAction<string | null>>
}
export function DepartmentSelect({ setDepartmentTypeChanged }: IsActiveSelectProps) {
    const [t, i18n] = useTranslation("global");
    return (
        <Select onValueChange={(value) => {
            setDepartmentTypeChanged(value);
        }}>            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t("departmentSelectTitle")} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="1">תכנון</SelectItem>
                    <SelectItem value="2">ייצור</SelectItem>
                    <SelectItem value="3">פיניש ובדיקה</SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
