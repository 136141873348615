import { Button } from "../../components/ui/button"
import { useTranslation } from 'react-i18next';

import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../../components/ui/sheet"
import { Icon } from "../loadersAndIcons/Icon"
import TaskDetails from "./TaskDetails"
import { Task } from "./TaskPreview"
type TaskDescriptionProps = {
    task: Task;
};

export function TaskDescription({ task }: TaskDescriptionProps) {
    const [t, i18n] = useTranslation("global")

    return (
        <Sheet >
            <SheetTrigger>
                <Button

                >
                    <Icon name='BookOpenText' />
                </Button>
            </SheetTrigger>

            <SheetContent>
                <SheetHeader>
                    <SheetTitle>{t("taskDescriptionTitle")}</SheetTitle>
                    <SheetDescription>
                        <TaskDetails task={task} />

                    </SheetDescription>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}
