import { Dispatch } from 'redux';
import { RootState } from '../index';
import { taskService } from "../../services/task.service"
import { SET_TASKS, ADD_USER, EDIT_USER, UPDATE_TASK, SET_REPORT_DETAILS, SET_USER_REPORTS, SET_ALL_USERS, REMOVE_USER, LOGIN, REMOVE_WORKER_FROM_WORKING_ARR, ADD_WORKER_TO_NOT_WORKING_ARR, ADD_WORKER_TO_WORKING_ARR, UPDATE_TASK_BY_ID, SET_WORKERS_ARR, SET_NOT_WORKERS_ARR, REMOVE_WORKER_FROM_NOT_WORKING_ARR } from "../reducers/task.reducer"
import { loginService } from '../../services/login.service';
import { utilService } from '../../services/util.service';
interface Task {
    id: string;
    name: string;
    column_values: ColumnValue[];
}
interface UserData {
    firstName: string,
    lastName: string,
    externalID: string,
    userName: string,
    password: string,

}
interface UpdatedUserData {
    firstName: string;
    lastName: string;
    userID: string;
    isActive: string | null;
    userTypeID: string | null;
    departmentID: string | null;
    pwdHash?: string; // Include pwdHash in the new type

}
interface UserDetails {
    startDate: string;
    endDate: string;
    employeeID: string;
}
interface ColumnValue {
    id: string;
    text: string;
}

interface Worker {
    boardName: string;
    employeeName: string;
    startDateTime: string;
    userID: string;
    workLogID: string;
}

interface TaskDetails {
    boardID: string;
    itemID: string;
    numberValue: number;
}

export function loadRobots(boardID: string, userType: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const tasks = await taskService.query(boardID, userType);
            const action = {
                type: SET_TASKS,
                tasks
            };
            dispatch(action);
        } catch (error) {
            console.log('error:', error);
        }
    };
}

export function setUser(phone: string, password: string, ipAddress: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const user = await loginService.checkLogin(phone, password, ipAddress);
            const action = { type: LOGIN, user }
            dispatch(action)
            return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}


export function fetchBossData() {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const data = await taskService.getEmployeesStatus();

            const action1 = { type: SET_WORKERS_ARR, workingArr: data.workingArr }
            dispatch(action1)
            const action2 = { type: SET_NOT_WORKERS_ARR, notWorkingArr: data.notWorkingArr }
            dispatch(action2)
            // return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function updateWorkingArr(userID: string, worker: Worker) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const action1 = { type: ADD_WORKER_TO_WORKING_ARR, worker, userID }
            dispatch(action1)
            const action = { type: REMOVE_WORKER_FROM_NOT_WORKING_ARR, userID }
            dispatch(action)
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function updateNotWorkingArr(userID: string, worker: Worker) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {

            const action1 = { type: REMOVE_WORKER_FROM_WORKING_ARR, userID }
            dispatch(action1)
            const action = { type: ADD_WORKER_TO_NOT_WORKING_ARR, worker }
            dispatch(action)
        } catch (error) {
            console.log('error:', error);
        }
    };
}





export function getusers() {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const users = await taskService.getAllUsers();
            const action1 = { type: SET_ALL_USERS, users }
            dispatch(action1)
            // return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function deleteUser(userID: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            await taskService.removeUser(userID);
            const action1 = { type: REMOVE_USER, userID }
            dispatch(action1)
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function addUser(userData: UserData, showToast, showExternalID2Error, showToastError, showToastError2, closeDialog, showUserNameExistError) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const res = await taskService.addUser(userData);
            if (res.result === -1) {
                // If the result is -1, the externalID is already in use, so show the error toast
                showToastError2();

            } else if (res.result === -2) {
                // If the result is -2, the username already exists, so show the username exists error
                showUserNameExistError();
            } else if (res.result === -3) {
                showExternalID2Error()
            }
            else {
                // If the result is not -1, proceed with adding the user
                const addedUser = {
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    externalID: userData.externalID,
                    userID: res.result,
                    userTypeID: userData.userType,

                    isActive: "1"
                };
                // Dispatch the action to add the user to the store
                const action1 = { type: 'ADD_USER', addedUser };
                dispatch(action1);
                closeDialog()

                // Show a success toast with the username
                showToast(userData.lastName);
            }
        } catch (error) {
            // If there is an error in the process, show the error toast
            showToastError();
            console.error('Error adding user:', error);
        }
    };
}

export function updateUser(userData: UpdatedUserData) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            await taskService.editUser(userData);

            const editedUser = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                userID: userData.userID,
                isActive: userData.isActive,
                userTypeID: userData.userTypeID,
                department: utilService.getDepartmentName(userData.departmentID)
            }

            const action1 = { type: EDIT_USER, editedUser }
            dispatch(action1)
            // return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function getUserReports(userDetails: UserDetails) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const reports = await taskService.getReports(userDetails);

            const action1 = { type: SET_USER_REPORTS, reports }
            dispatch(action1)
            // return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}
export function setReportDetails(reportDetails: UserDetails) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const reportsDetails = await taskService.getReportDetails(reportDetails);

            const action1 = { type: SET_REPORT_DETAILS, reportsDetails }
            dispatch(action1)
            // return user
        } catch (error) {
            console.log('error:', error);
        }
    };
}
