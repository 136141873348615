import { useState, useEffect } from 'react';
import { Route, HashRouter as Router, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import './assets/scss/global.css';
import { AppHeader } from './cmps/AppHeader';
import { AppFooter } from './cmps/AppFooter';
import { Toaster } from './components/ui/toaster';
import TaskIndex from './views/TaskIndex';
import Login from './views/Login';
import ChartIndex from './views/ChartIndex';
import DataTableDemo from './views/DataTableDemo';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, LineElement, PointElement } from 'chart.js';
import { taskService } from './services/task.service';
import BossPage from './views/BossPage';
import UsersManage from './views/UsersManage';
import MainReport from './views/MainReport';
import Reports from './views/Reports';
import { requestFirebaseToken, onMessageListener } from './services/firebase';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);



function useDocumentTitle() {
    const location = useLocation();
    useEffect(() => {
        const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
        const routeName = location.pathname.substring(1).replace(/-/g, ' ');
        const title = routeName.split('/').map(capitalize).join(' | ');
        document.title = `Romday${title ? ` | ${title}` : ''}`;
    }, [location]);
}


function MainApp() {
    useDocumentTitle();



    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Time Spent on Tasks',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
        }],
    });

    const options = {
        scales: {
            y: {
                type: 'linear',
                beginAtZero: true,
            },
            x: {
                type: 'category',
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            },
        },

    };

    const location = useLocation()
    const navigate = useNavigate()
    console.log("location.pathname", location.pathname)

    useEffect(() => {


        requestFirebaseToken()
        console.log("location.pathname", location.pathname)


        onMessageListener().then(payload => {


            if (payload.fcmOptions && payload.fcmOptions.link) {
                try {
                    const linkObject = JSON.parse(payload.fcmOptions.link);

                    const boardName = linkObject.boardName;

                    // if (window.location.pathname.includes('/users-data')) {
                    window.location.reload();

                    // }




                } catch (error) {
                    console.error('Error parsing fcmOptions.link or dispatching task:', error);
                }
            }
        }).catch(err => console.log('failed: ', err));



    }, []);

    useEffect(() => {
        const user = localStorage.getItem('fullName_rom')
        console.log("location.pathname", location.pathname)
        if (user && location.pathname === '/') {
            navigate('/home')
        }
        if (!user && location.pathname !== '/') {

            navigate('/')
        }

    }, [location, navigate]);
    const [t, i18n] = useTranslation("global")
    useEffect(() => {
        let rawlang = localStorage.getItem('language_rom');
        const lang = rawlang ? rawlang.replace(/^"|"$/g, '').replace(/\\"/g, '') : 'en';
        i18n.changeLanguage(lang);


        if (!rawlang) {
            localStorage.setItem('language_rom', 'en');
        }
    }, [i18n]);



    const isRtl = i18n.language === "he";

    return (
        <div className="flex flex-col min-h-screen" dir={isRtl ? "rtl" : "ltr"}>
            {location.pathname !== '/' && <AppHeader />}
            <main className="flex-grow m-10" dir={isRtl ? "rtl" : "ltr"}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={<TaskIndex />} />
                    <Route path="/users-data" element={<BossPage />} />
                    <Route path="/users-manage" element={<UsersManage />} />
                    <Route path="/chart" element={<ChartIndex boardID='1349699010' />} />
                    <Route path="/users" element={<DataTableDemo />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/report-details" element={<MainReport />} />
                </Routes>
            </main>
            <AppFooter />
            <Toaster />
        </div>
    );
}



function App() {
    return (
        // <Router basename="/mondayTasks/build">
        <Router>
            <MainApp />
        </Router >
    )
}

export default App;






// new app.jssssssssssssssssssssssssssssssssssssssssss
// import { Route, HashRouter as Router, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// import './assets/scss/global.scss'
// import { AppHeader } from './cmps/AppHeader'
// import { CategoryIndex } from './views/CategoryIndex'
// import { CategoryDetails } from './views/CategoryDetails';
// import { SubCategoryDetails } from './views/SubCategoryDetails';
// import { Login } from './views/Login';
// import { UserMsg } from './cmps/UserMsg';
// import { useEffect } from 'react';
// import { BussinessTypesIndex } from './views/BussinessTypesIndex';
// import { FastExport } from './views/FastExport';
// import { categoryService } from './services/category.service';
// import { Video } from './views/Video';
// import { BossData } from './views/BossData';
// import { AllowDownloadVideo } from './views/AllowDownloadVideo';
// import { DuplicatesManage } from './views/DuplicatesManage';
// import { WithoutImages } from './views/WithoutImages';


// function MainApp() {

//     const location = useLocation()
//     const params = useParams()
//     const navigate = useNavigate()
//     useEffect(() => {
//         const user = localStorage.getItem('fullName_GC')

//         if (user && location.pathname === '/') {
//             navigate('/bussinss/types')
//         }
//         if (!user && location.pathname !== '/') {
//             navigate('/')
//         }

//     }, [location, navigate]);

//     useEffect(() => {
//         console.log("mamamamam")
//         categoryService.isUserActive(navigate)

//     }, [params]);

//     return (
//         <section className="main-app">
//             <UserMsg />
//             {location.pathname !== '/' && <AppHeader />}
//             <main className="container">
//                 <Routes>
//                     <Route path="/" element={<Login />} />
//                     <Route path="/bussinss/types" element={<BussinessTypesIndex />} />
//                     <Route path="/home" element={<CategoryIndex />} />
//                     <Route path="/station" element={<CategoryDetails />} />
//                     <Route path="/products" element={<SubCategoryDetails />} />
//                     <Route path="/fast/export" element={<FastExport />} />
//                     <Route path="/guide" element={<Video />} />
//                     <Route path="/allow-downloads" element={<AllowDownloadVideo />} />
//                     <Route path="/users-data" element={<BossData />} />
//                     <Route path="/duplicates/manage" element={<DuplicatesManage />} />
//                     <Route path="/without/images" element={<WithoutImages />} />
//                 </Routes>
//             </main>
//         </section>
//     )
// }


// function App() {
//     return (
//         <Router>
//             <MainApp />
//         </Router>
//     )
// }

// export default App;
