import { Button } from "../../components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
interface SearchDurationInChartProps {
    onSearch: () => void;
    setMinValue: (value: string) => void;
    setMaxValue: (value: string) => void;
}
export function SearchDurationInChart({ onSearch, setMinValue, setMaxValue }: SearchDurationInChartProps) {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">Search</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Search boards by minutes </DialogTitle>

                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Min
                        </Label>
                        <Input
                            id="min"
                            type="number"
                            placeholder="Enter min value"
                            onChange={(e) => setMinValue(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            Max
                        </Label>
                        <Input
                            id="max"
                            type="number"
                            placeholder="Enter max value"
                            onChange={(e) => setMaxValue(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" onClick={onSearch}>Search</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
