import React from 'react';
import { useTranslation } from 'react-i18next';

interface ColumnValue {
    id: string;
    text: string;
}

interface Task {
    id: string;
    name: string;
    column_values: ColumnValue[];
}

interface TaskDetailsProps {
    task: Task;
}

const getColumnValue = (columnValues: ColumnValue[], id: string) =>
    columnValues.find(column => column.id === id)?.text || '';

const TaskDetails: React.FC<TaskDetailsProps> = ({ task }) => {
    const [t, i18n] = useTranslation("global");
    const { id, name, column_values } = task;

    const isRtl = i18n.language === "he";

    const columnValueMap = React.useMemo(() => {
        return column_values.reduce((acc, { id, text }) => {
            acc[id] = text;
            return acc;
        }, {} as Record<string, string>);
    }, [column_values]);

    return (
        <div className={`p-4 flex gap-4 flex-col ${isRtl ? "rtl" : "ltr"}`}>
            <p className="text-lg font-semibold">{t("taskDescriptionBoardID")}: <span className="font-normal">{id}</span></p>
            <p className="text-lg font-semibold">{t("taskDescriptionBoardName")}: <span className="font-normal">{name}</span></p>
            <p className="text-lg font-semibold">{t("taskDescriptionBoardNumber")}: <span className="font-normal">{getColumnValue(column_values, 'text')}</span></p>
            <p className="text-lg font-semibold">{t("taskDescriptionStatus")}: <span className="font-normal">{columnValueMap['status_1']}</span></p>
            <p className="text-lg font-semibold">{t("taskDescriptionDuration")}: <span className="font-normal">{columnValueMap['numbers6']} {t("taskDescriptionDurationUnit")}</span></p>
        </div>
    );
};

export default TaskDetails;
