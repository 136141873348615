import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../store/actions/task.actions';
import { ValidationModal } from '../cmps/modals/ValidationModal';
import { AnyAction } from 'redux';
import { RootState } from '../store/index';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { taskService } from '../services/task.service';
import { storageService } from '../services/storage.service';
import { Loader } from '../cmps/loadersAndIcons/Loader';
import { LanguageSelect } from '../cmps/LanguageSelect';
import transition from "../transition"
import { requestFirebaseToken } from '../services/firebase';

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showValidationModal, setShowValidationModal] = useState(false)
    const [validationError, setValidationError] = useState('')
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const [t, i18n] = useTranslation("global")
    const isRtl = i18n.language === "he";

    const goToHomePage = async (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault()
        if (username !== '' && password !== '') {
            const ipAddress = await taskService.getIpAddress()
            const res = await dispatch(
                setUser(username, password, ipAddress)
            )
            // navigate('/home')
            // debugger
            if (res.result === '0') {
                setShowValidationModal(true)
                setValidationError(t("loginValidation1"))
                return
            }

            else {

                storageService.store('fullName_rom', res.fullName)
                storageService.store('fullName_rom', res.fullName, true)
                storageService.store('userID_rom', res.result)
                storageService.store('userID_rom', res.result, true)
                storageService.store('date_rom', Date.now())
                storageService.store('date_rom', Date.now(), true)
                storageService.store('userType_rom', res.userTypeID)
                storageService.store('userType_rom', res.userTypeID, true)
                requestFirebaseToken()


                navigate('/home')


            }

        } else {
            if (!username || !password) {
                setShowValidationModal(true);

                if (!username && !password)
                    setValidationError(t("loginValidation3"));
                else if (!username)
                    setValidationError(t("loginValidation4"));
                else
                    setValidationError(t("loginValidation5"));


                return;
            }


        }
    }

    function handleCloseModal() {
        setShowValidationModal(false)
    }


    const setLanguage = (language: string | null) => {
        i18n.changeLanguage(language);
        storageService.store('language_rom', language);
    };




    return (
        <div className="container-login">
            <div className='mb-4' >
                <div className="logo__circle">
                    <img src='https://online.zmnsoft.net/rom/mondayTasks/images/rom-logo.png' alt='rom-logo' className='logo__svg' />
                </div>
            </div >
            <form className="form" >
                <div className="form__group">
                    <div className="form__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z" /></svg></div>
                    <input
                        className="form__control"
                        type="text"
                        placeholder="username"
                        value={username}
                        onChange={handleUsernameChange}
                    />                </div>
                <div className="form__group">
                    <div className="form__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" /></svg></div>
                    <input
                        className="form__control"
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                {/* <div className="form__group">
                    <div className="form__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" /></svg></div>
                    <LanguageSelect setLanguage={setLanguage} />

                </div> */}
                <div>
                    <button className="form__button" type="submit" onClick={goToHomePage}>
                        {t("loginButton")}
                    </button>
                </div>
            </form >
            {showValidationModal && (
                <ValidationModal msg={validationError} onClose={handleCloseModal} />
            )
            }
        </div >
    )
}


export default transition(Login)