import React from 'react';
import { useTranslation } from 'react-i18next';

interface TimePickerPopupProps {
  defaultTime: string
  time: string;
  setTime: (time: string) => void;
}

export function StartTimePickerPopup({ time, setTime, defaultTime }: TimePickerPopupProps) {

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };
  const [t, i18n] = useTranslation("global")
  const isRtl = i18n.language === "he";
  return (
    <div className="flex items-center p-4 rounded-lg gap-3" dir={isRtl ? "rtl" : "ltr"}>
      <label htmlFor={`timePicker-start`} className="mb-2 text-white">{t("timePickerStart")}</label>
      <input
        type="time"
        id={`timePicker-start`}
        name={`timePicker-start`}
        className="px-4 py-2 text-black border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        value={time}
        onChange={handleTimeChange}
      />
    </div>
  );
}
