import { useEffect, useState } from 'react';
import { TaskList } from '../cmps/task/TaskList';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { loadRobots } from '../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../store/index';
import { InputWithLabel } from '../cmps/InputWithLabel';
import { useTranslation } from 'react-i18next';
import transition from "../transition"
import { AnimatePresence } from 'framer-motion';

import { TooltipDemo } from '../cmps/TooltibDemo';
import { TaskSkeleton } from '../cmps/Skeletons/TaskSkeleton';
function TaskIndex() {
    const [filter, setFilter] = useState('');
    const tasks = useSelector((storeState: RootState) => storeState.taskModule.tasks);
    const [viewMode, setViewMode] = useState('grid');
    const [t, i18n] = useTranslation("global");

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
    const tableViewMode = () => {
        setViewMode('table');
    };
    const gridViewMode = () => {
        setViewMode('grid');
    };
    useEffect(() => {
        const boardID = "1349699010"
        const rawUserType = localStorage.getItem('userType_rom');
        const userType = rawUserType ? rawUserType.replace(/^"|"$/g, '') : 'Unknown';
        dispatch(loadRobots(boardID, userType));
    }, [dispatch]);

    const filteredTasks = tasks
        ? tasks?.filter(task =>
            task.name.toLowerCase().includes(filter.toLowerCase()) ||
            task.column_values.some(column =>
                column.id === 'text' && column.text.toLowerCase().includes(filter.toLowerCase()))
        ) : [];
    const isRtl = i18n.language === "he";

    if (!tasks) return (
        <>
            <TaskSkeleton />
        </>
    )

    return (
        <section className='robot-index' dir={isRtl ? "rtl" : "ltr"}>
            <h1 className=" w-full text-center top-0 mt-4 font-bold text-2xl neon-effect">{t("taskIndexTitle")}</h1>


            {/* <div className="flex justify-end items-center gap-5 p-4">
                <button className='border-white' onClick={tableViewMode} >

                    <TooltipDemo IconName={'Table'} titleName={"Switch to table"} />
                </button>
                <button className='border-white' onClick={gridViewMode} >

                    <TooltipDemo IconName={'CreditCard'} titleName={"Switch to cards"} />
                </button>


            </div> */}

            <InputWithLabel onChange={setFilter} />

            <AnimatePresence>
                <TaskList tasks={filteredTasks} viewMode={viewMode} />
            </AnimatePresence>
        </section>
    );
}


export default transition(TaskIndex)