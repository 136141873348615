import { useState } from "react";
import { Button } from "../../components/ui/button"
import { useToast } from "../../components/ui/use-toast"
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { Icon } from "../loadersAndIcons/Icon";
import { IsActiveSelect } from "../IsActiveSelect";
import { useTranslation } from 'react-i18next';
import { UserTypeSelect } from "../UserTypeSelect";
import { DepartmentSelect } from "../DepartmentSelect";

interface EditUserModalProps {
    firstName: string;
    lastName: string;
    userID: string;
    activeStatus: string | null;
    userTypeStatus: string | null;
    departmentType: string | null;
    pwdHash?: string;  // Add this line (optional property)
}


export function EditUserModal({ firstName, lastName, userID, activeStatus, userTypeStatus, departmentType }: EditUserModalProps) {
    const { toast } = useToast()

    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const [name, setName] = useState(firstName);
    const [username, setUsername] = useState(lastName);
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [isActive, setIsActive] = useState<string | null>(activeStatus);
    const [userType, setUserType] = useState<string | null>(userTypeStatus);
    const [departmentID, setDepartmentType] = useState<string | null>(departmentType);
    const [t, i18n] = useTranslation("global");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)
    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const handleCPassChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmedPassword(event.target.value);
    const handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const userData = {
            firstName: name,
            lastName: username,
            userID: userID,
            isActive: isActive,
            userTypeID: userType,
            departmentID: departmentID,
            // Add the isActive state
        };

        if (name === '' || username === '') {
            showFillFieldsNotPassword()
            return
        }
        if (showResetPassword) {
            if (password === "" || confirmedPassword === "") {
                showFillFields()
                // alert("Please fill the password fields or hide them")
                return
            } else {
                if (password !== confirmedPassword) {
                    // alert("passwords don't match")
                    dontMatch()
                    return
                } else {
                    userData.pwdHash = password
                }
            }
        }

        try {
            dispatch(updateUser(userData))
            showToast(username)
        } catch (error) {
            console.error('Error adding user:', error);
            showToastError()
        }
    };
    // "toastOneTitle": "המשתמש נוסף בהצלחה",
    // "toastOneDescription": "הוספת משתמש זה",
    // "toastTwoVariant": "הרסני",
    // "toastTwoTitle": "אופס! משהו השתבש.",
    // "toastTwoDescription": "הייתה בעיה עם בקשתך. אינך יכול להוסיף את המשתמש",
    // "toastThreeVariant": "הרסני",
    // "toastThreeTitle": "אופס! משהו השתבש.",
    // "toastThreeDescription": "אנא מלא את שדות הסיסמה או הסתר אותם",
    // "toastFourVariant": "הרסני",
    // "toastFourTitle": "אופס! משהו השתבש.",
    // "toastFourDescription": "הסיסמאות אינן תואמות"
    function showToast(username: string) {
        toast({
            title: t("toastOneTitle"),
            description: `${t("toastOneDescription")} ${username} `,
        })
    }
    function showToastError() {
        toast({
            variant: "destructive",
            title: t("toastTwoTitle"),
            description: t("toastTwoDescription"),
        })
    }
    function showFillFields() {
        toast({
            variant: "destructive",
            title: t("toastThreeTitle"),
            description: t("toastThreeDescription"),
        })
    }
    function dontMatch() {
        toast({
            variant: "destructive",
            title: t("toastFourTitle"),
            description: t("toastFourDescription"),
        })
    }
    function showFillFieldsNotPassword() {
        toast({
            variant: "destructive",
            title: t("toast20Title"),
            description: t("toast20Description"),
        })
    }

    function handleShowPasswordFields(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault(); // Prevent the default form submit action

        setShowResetPassword(true)
    }
    function handleHidePasswordFields(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault(); // Prevent the default form submit action

        setShowResetPassword(false)
    }
    const isRtl = i18n.language === 'he';

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Icon name="Pencil" className="cursor-pointer" />
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <form onSubmit={handleSubmit} dir={isRtl ? "rtl" : "ltr"}> {/* Add this line */}
                    <DialogHeader>
                        <DialogTitle>{t("EditUserModalTitle")}</DialogTitle>

                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right text-red-600 ">
                                {t("EditUserModalFirstName")}:*
                            </Label>
                            <Input
                                id="name"
                                defaultValue={name}
                                className="col-span-3"
                                onChange={handleNameChange} // Attach the handler here
                            />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right text-red-600">
                                {t("EditUserModalLastName")}:*
                            </Label>
                            <Input
                                id="username"
                                defaultValue={username}
                                className="col-span-3"
                                onChange={handleUsernameChange} // And here
                            />
                        </div>

                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right text-red-600">
                                {t("EditUserModalStatus")}
                            </Label>
                            <IsActiveSelect isActive={isActive} setIsActive={setIsActive} />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right text-red-600" >
                                {t("EditUserModalUserType")}
                            </Label>
                            <UserTypeSelect setUserTypeChanged={setUserType} />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4 ">
                            <Label htmlFor="password" className="text-right text-red-600" >
                                {t("departmentSelectTitle")}:*
                            </Label>
                            <DepartmentSelect setDepartmentTypeChanged={setDepartmentType} />
                        </div>
                        {showResetPassword &&
                            <>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="password" className="text-right">
                                        {t("EditUserModalPassword")}
                                    </Label>
                                    <Input
                                        id="password"
                                        type="password"

                                        defaultValue={password}
                                        className="col-span-3"
                                        onChange={handlePassChange} // And here
                                        placeholder={t("EditUserModalPlaceholderPassword")}
                                    />

                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="cPassword" className="text-right">
                                        {t("EditUserModalConfirmPassword")}
                                    </Label>
                                    <Input
                                        id="Password"
                                        type="password"

                                        defaultValue={confirmedPassword}
                                        className="col-span-3"
                                        onChange={handleCPassChange} // And here
                                        placeholder={t("EditUserModalConfirmPlaceholderPassword")}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    <DialogFooter>

                        {
                            showResetPassword ? (
                                <Button onClick={(e) => handleHidePasswordFields(e)}>{t("EditUserModalHidePassword")}</Button>

                            ) : (
                                <Button onClick={(e) => handleShowPasswordFields(e)}>{t("EditUserModalResetPassword")}</Button>

                            )
                        }

                        <Button type="submit">{t("EditUserModalSaveChanges")}</Button>
                    </DialogFooter>
                </form>
            </DialogContent>

        </Dialog>
    )
}
