import { useState } from "react";
import { Button } from "../../components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"

import { Icon } from "../loadersAndIcons/Icon"
import { StartTimePickerPopup } from "../pickers/StartTimePickerPopup"
import { EndTimePickerPopup } from "../pickers/EndTimePickerPopup"
import ToastComponent from "../ToastComponent";
import { taskService } from "../../services/task.service";
import { EditIconToolTip } from "../EditIconToolTip";

import { useTranslation } from 'react-i18next';
interface UpdateReportDateModalProps {
    workLogID: string;
    startDateTime: string;
    endDateTime: string;
    startedDate: string;
}

export function UpdateReportDateModal({ workLogID, startDateTime, endDateTime, startedDate }: UpdateReportDateModalProps) {
    console.log("startDateTime", startDateTime)
    console.log("endDateTime", endDateTime)
    console.log("typeof(startDateTime)", typeof (startDateTime))
    console.log("typeof(endDateTime)", typeof (endDateTime))
    const extractTime = (datetimeString: string) => {
        const timePart = datetimeString.split(' ')[1];
        return timePart;
    };
    const [isModalOpen, setIsModalOpen] = useState(true); // Add this state

    const [t, i18n] = useTranslation("global")
    const isRtl = i18n.language === "he";
    const [startTime, setStartTime] = useState((startDateTime));
    const [endTime, setEndTime] = useState((endDateTime));
    const [toastProps, setToastProps] = useState<{
        variant: 'success' | 'destructive';
        title: string;
        description: string;
    } | null>(null);
    const handleSubmit = async () => {

        try {

            console.log("extractDate(startDateTime)", extractTime(startDateTime))

            const updatedReportDuration = {
                startDate: extractDate(startedDate),
                endDate: extractDate(startedDate),
                startTime: startTime,
                endTime: endTime,
                workLogID: workLogID,
            }
            console.log("updatedReportDuration", updatedReportDuration)
            const res = await taskService.updateReportDuration(updatedReportDuration);

            const rawUsername = localStorage.getItem('userID_rom');
            const username = rawUsername ? rawUsername.replace(/^"|"$/g, '') : 'Unknown';
            const log = {
                workLogID: workLogID,
                token: username
            }
            await taskService.insertLogToUpdateLogs(log);
            setIsModalOpen(false);
            window.location.reload();

            showToast()
        } catch (err) {
            setIsModalOpen(false);

            showToastError()
        }
    };
    const extractDate = (datetimeString: string) => {
        const timePart = datetimeString.split(' ')[0];
        return timePart;
    };


    function showToast() {

        setToastProps({
            variant: 'success',
            title: t("toastEightTitle"),
            description: `${t("toastEightDescription1")} ${startTime} ${t("toastEightDescription2")} ${endTime}`,

        });
    }

    function showToastError() {

        setToastProps({
            variant: 'destructive',
            title: t("toastNineTitle"),
            description: t("toastNineDescription"),
        });
    }

    return (
        <>
            {isModalOpen &&
                <Dialog>
                    <DialogTrigger asChild>

                        <Icon name="Pencil" className='cursor-pointer' color="green" />
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                            <DialogTitle>{t("updateDurationModalTitle")}</DialogTitle>

                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                            <StartTimePickerPopup time={startTime} setTime={setStartTime} defaultTime={(startDateTime)} />
                            <EndTimePickerPopup time={endTime} setTime={setEndTime} defaultTime={(endDateTime)} />
                        </div>
                        <DialogFooter>
                            <div dir={isRtl ? "rtl" : "ltr"}>

                                <Button type="submit" onClick={handleSubmit}>
                                    {t("updateDurationModalButton")}
                                </Button>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            }
            {toastProps && (
                <ToastComponent
                    variant={toastProps.variant}
                    title={toastProps.title}
                    description={toastProps.description}
                />
            )}
        </>
    )
}
