import { Button } from "../components/ui/button"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../components/ui/tooltip"
import { Icon } from "./loadersAndIcons/Icon"
interface TooltipDemoProps {
    IconName: string;
    titleName: string;
}
export function TooltipDemo({ IconName, titleName }: TooltipDemoProps) {
    return (
        <TooltipProvider >
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button variant="outline">        <Icon name={IconName} color={"green"} className="h-6 w-6" /></Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>{titleName}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
