import * as React from "react"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"
import { useTranslation } from 'react-i18next';

interface IsActiveSelectProps {
    isActive: string | null,
    setIsActive: React.Dispatch<React.SetStateAction<string | null>>
}
export function IsActiveSelect({ isActive, setIsActive }: IsActiveSelectProps) {
    const [t, i18n] = useTranslation("global");
    return (
        <Select onValueChange={(value) => {
            setIsActive(value);
        }}>            <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={t("isActiveSelectTitle")} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem value="1">{t("isActiveSelectOption1")}</SelectItem>
                    <SelectItem value="0">{t("isActiveSelectOption2")}</SelectItem>

                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
