import * as React from "react"
import { Calendar as CalendarIcon } from "lucide-react"
import { parse, format } from 'date-fns';

import { cn } from "../../lib/utils"
import { Button } from "../../components/ui/button"
import { Calendar } from "../../components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../components/ui/popover"
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
    onDateChange: (newDate: string) => void;
    selectedDate?: Date;
}

export function DatePicker({ onDateChange, selectedDate }: DatePickerProps) {
    const parsedDate = selectedDate ? parse(selectedDate, "dd/MM/yyyy", new Date()) : undefined;
    const [date, setDate] = React.useState<Date | undefined>(parsedDate);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [t, i18n] = useTranslation("global");
    const handleSelectDate = (newDate: Date | undefined) => {
        setDate(newDate);
        setIsPopoverOpen(false);
        if (newDate) {
            onDateChange(format(newDate, "dd/MM/yyyy"));
        }
    };

    const handleButtonClick = () => {
        setIsPopoverOpen(true);
    };


    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    onClick={handleButtonClick}
                    variant={"outline"}
                    className={cn(
                        "w-[280px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date ? format(date, "dd/MM/yyyy") : <span>{t("datePickerFrom")}</span>}
                </Button>
            </PopoverTrigger>
            {isPopoverOpen && (
                <PopoverContent className="w-auto p-0">
                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={handleSelectDate}
                        initialFocus
                    />
                </PopoverContent>
            )}
        </Popover>
    );
}
