import { useEffect, useState } from "react";
import { Button } from "../components/ui/button"
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../components/ui/sheet"
import { taskService } from "../services/task.service";
import { Loader } from "./loadersAndIcons/Loader";
import { WhoUpdateReportTable } from "./tables/WhoUpdateReportTable";
import { EditedIconToolTip } from "./EditedIconToolTip";
import { useTranslation } from 'react-i18next';

interface WhoUpdateReportTableProps {
    workLogID: string;

}
interface UserData {
    fullName: string;
    createdDateTime: string;
}

export function WhoUpdateReportSheet({ workLogID }: WhoUpdateReportTableProps) {

    const [data, setData] = useState<UserData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [t, i18n] = useTranslation("global")
    useEffect(() => {
        const fetchUserData = async () => {
            try {

                setIsLoading(true)
                const usersData = await taskService.getLogFromUpdateLogs(workLogID);

                setData(usersData)
            } catch (err) {

                console.log("err", err)
                setIsLoading(false)
            }

        };

        fetchUserData();
    }, []);

    // if (isLoading) return <Loader />


    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="link">
                    <EditedIconToolTip />

                </Button>

            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>{t("whoChangeTheDurationTitle")}</SheetTitle>

                </SheetHeader>
                <div className="grid gap-4 py-4">
                    <WhoUpdateReportTable whoUpdateData={data} />


                </div>

            </SheetContent>
        </Sheet>
    )
}
