
export const SET_TASKS = 'SET_TASKS'
export const SET_USER_REPORTS = 'SET_USER_REPORTS'
export const ADD_USER = 'ADD_USER'
export const EDIT_USER = 'EDIT_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SET_BATATA = 'SET_BATATA'
export const ADD_Task = 'ADD_Task'
export const REMOVE_Task = 'REMOVE_Task'
export const UPDATE_TASK = 'UPDATE_TASK'
export const SET_FILTER_BY = 'SET_FILTER_BY'
export const LOGIN = 'LOGIN'
export const UPDATE_TASK_BY_ID = 'UPDATE_TASK_BY_ID'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_WORKERS_ARR = 'SET_WORKERS_ARR'
export const SET_NOT_WORKERS_ARR = 'SET_NOT_WORKERS_ARR'
export const REMOVE_WORKER_FROM_NOT_WORKING_ARR = 'REMOVE_WORKER_FROM_NOT_WORKING_ARR'
export const ADD_WORKER_TO_WORKING_ARR = 'ADD_WORKER_TO_WORKING_ARR'
export const REMOVE_WORKER_FROM_WORKING_ARR = 'REMOVE_WORKER_FROM_WORKING_ARR'
export const ADD_WORKER_TO_NOT_WORKING_ARR = 'ADD_WORKER_TO_NOT_WORKING_ARR'
export const SET_REPORT_DETAILS = 'SET_REPORT_DETAILS'
interface TaskState {
    tasks: Task[] | null;
    users: User[] | [];
    reports: Report[] | [];
    reportDetails: ReportDetails[] | null;
    workingArr: Worker[] | null;
    notWorkingArr: Worker[] | null;
    username: string,
    filterBy: {
        model: string;
        type: string;
        minBatteryStatus: number;
        maxBatteryStatus: number;
    };
}
;

interface Report {
    employeeName: string;
    endDateTime: string;
    startDateTime: string;
    userID: string;
    workLogID: string;

}
interface ReportDetails {
    employeeName: string;
    endDateTime: string;
    startDateTime: string;
    userID: string;
    workLogID: string;
    boardName: string;

}

interface ColumnValue {
    id: string;
    text: string;
}


interface Task {
    id: string;
    name: string;
    column_values: ColumnValue[];
}

interface User {
    firstName: string,
    lastName: string,
    userID: string,
    department: string,
    isActive: string

}


interface Worker {
    boardName: string;
    employeeName: string;
    startDateTime: string;
    userID: string;
    workLogID: string;
}



type TaskAction =
    | { type: 'SET_TASKS'; tasks: Task[] }
    | { type: 'SET_USER_REPORTS'; reports: Report[] }
    | { type: 'SET_REPORT_DETAILS'; reportsDetails: ReportDetails[] }
    | { type: 'ADD_USER'; addedUser: User }
    | { type: 'EDIT_USER'; editedUser: User }
    | { type: 'REMOVE_USER'; userID: string }
    | { type: 'SET_ALL_USERS'; users: User[] }
    | { type: 'SET_NOT_WORKERS_ARR'; notWorkingArr: Worker[] }
    | { type: 'ADD_WORKER_TO_WORKING_ARR'; worker: Worker }
    | { type: 'ADD_WORKER_TO_NOT_WORKING_ARR'; worker: Worker }
    | { type: 'REMOVE_WORKER_FROM_NOT_WORKING_ARR'; userID: string }
    | { type: 'REMOVE_WORKER_FROM_WORKING_ARR'; userID: string }
    | { type: 'SET_WORKERS_ARR'; workingArr: Worker[] }
    | { type: 'LOGIN'; user: string }

    | { type: 'ADD_Task'; Task: Task }
    | { type: 'REMOVE_Task'; TaskId: string }
    | { type: 'UPDATE_TASK'; Task: Task }
    | { type: 'SET_FILTER_BY'; filterBy: TaskState['filterBy'] };
const INITIAL_STATE: TaskState = {
    tasks: null,
    reports: [],
    reportDetails: null,
    users: [],
    workingArr: null,
    notWorkingArr: null,
    username: "",
    filterBy: {
        model: '',
        type: '',
        minBatteryStatus: 0,
        maxBatteryStatus: 0,
    },
};


export function taskReducer(state: TaskState = INITIAL_STATE, action: TaskAction): TaskState {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks
            };
        case SET_REPORT_DETAILS:
            return {
                ...state,
                reportDetails: action.reportsDetails
            };
        case SET_USER_REPORTS:
            return {
                ...state,
                reports: action.reports
            };
        case SET_ALL_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_WORKERS_ARR:
            return {
                ...state,
                workingArr: action.workingArr
            };
        case REMOVE_WORKER_FROM_NOT_WORKING_ARR:
            return {
                ...state,
                notWorkingArr: state.notWorkingArr ? state.notWorkingArr.filter(worker => worker.userID !== action.userID) : null
            };
        case REMOVE_USER:
            return {
                ...state,
                users: state.users ? state.users.filter(user => user.userID !== action.userID) : null
            };
        // return {
        //     ...state,
        //     users: state.users.map(user =>
        //         user.userID === action.userID ? { ...user, isActive: "0" } : user
        //     )
        // };
        case ADD_USER:
            return {
                ...state,
                users: state.users ? [...state.users, action.addedUser] : [action.addedUser]
            };
        case EDIT_USER:
            return {
                ...state,
                users: state.users ? state.users.map(user => user.userID === action.editedUser.userID ? action.editedUser : user) : null
            };
        case REMOVE_WORKER_FROM_WORKING_ARR:
            return {
                ...state,
                workingArr: state.workingArr ? state.workingArr.filter(worker => worker.userID !== action.userID) : null
            };
        case SET_NOT_WORKERS_ARR:
            return {
                ...state,
                notWorkingArr: action.notWorkingArr
            };
        case LOGIN:
            return {
                ...state,
                username: action.user
            };

        case ADD_Task:
            return {
                ...state,
                tasks: state.tasks ? [...state.tasks, action.Task] : [action.Task]
            };
        case ADD_WORKER_TO_WORKING_ARR:
            return {
                ...state,
                workingArr: state.workingArr ? [...state.workingArr, action.worker] : [action.worker]
            };
        case ADD_WORKER_TO_NOT_WORKING_ARR:
            return {
                ...state,
                notWorkingArr: state.notWorkingArr ? [...state.notWorkingArr, action.worker] : [action.worker]
            };
        case REMOVE_Task:
            return {
                ...state,
                tasks: state.tasks ? state.tasks.filter(Task => Task.id !== action.TaskId) : null
            };
        case UPDATE_TASK:
            return {
                ...state,
                tasks: state.tasks ? state.tasks.map(Task => Task.name === action.Task.id ? action.Task : Task) : null
            };
        case SET_FILTER_BY:
            return {
                ...state,
                filterBy: { ...action.filterBy }
            };

        default:
            return state;
    }
}
