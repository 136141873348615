import { storageService } from '../services/storage.service'
import { utilService } from '../services/util.service';

export const loginService = {
  checkLogin,
}

async function checkLogin(phone: string, password: string, ipAddress: string) {
  // const ipAddress = "sa"


  const apiUrl = utilService.appendQueryParams(
    `https://rom.lan-wan.net/mondayTasks/backend/login/checkLogin.php`,
    { userName: phone, pass: password, ipAddress }
  );
  console.log("aoiUrl: ", apiUrl);
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }


    const parsedData = await utilService.getData(response)

    return parsedData

  } catch (error) {
    console.log(error)
    throw error
  }
}
