import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

// Define the type for our chart data
interface LineChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
    }[];
}

// Define the props for our component
interface LineChartProps {
    data: LineChartData;
    options: ChartOptions;
}

const LineChartComponent: React.FC<LineChartProps> = ({ data, options }) => {
    return (
        <>
            {/* <h2>Bar Chart</h2> */}
            <Line data={data} options={options} />
        </>
    );
};

export default LineChartComponent;
