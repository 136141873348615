import { Input } from "../components/ui/input";

import { useTranslation } from 'react-i18next';
interface InputWithLabelProps {
  onChange: (value: string) => void;
}

export function InputWithLabel({ onChange }: InputWithLabelProps) {
  const [t, i18n] = useTranslation("global")
  return (
    <div className="grid w-full max-w-sm items-center gap-1.5 ml-2">
      {/* <Label htmlFor="email">Search by name</Label> */}
      <Input
        type="text"
        placeholder={t("taskIndexInputPlaceholder")}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
