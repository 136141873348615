import * as React from "react"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select"
import { useTranslation } from 'react-i18next';
// import { storageService } from "../services/storage.service";
import { utilService } from "../services/util.service";

interface LanguageSelectProps {
    setLanguage: React.Dispatch<React.SetStateAction<string | null>>
}
export function LanguageSelect({ setLanguage }: LanguageSelectProps) {
    const [t, i18n] = useTranslation("global");
    const isRtl = i18n.language === "he";

    let rawlang = localStorage.getItem('language_rom');
    const lang = rawlang ? rawlang.replace(/^"|"$/g, '').replace(/\\"/g, '') : '';


    return (
        <div dir={isRtl ? "rtl" : "ltr"} className="w-[20px]">

            <Select onValueChange={(value) => {
                setLanguage(value);
            }}>            <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder={lang !== "" ? utilService.formatLanguage(lang) : t("languageSelectTitle")} />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value="en">English</SelectItem>
                        <SelectItem value="he">עִברִית</SelectItem>

                    </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    )
}
