import React, { useState, useEffect } from 'react';
import BarChartComponent from '../cmps/charts/BarChartComponent';
import LineChartComponent from '../cmps/charts/LineChartComponent';
import PieChartComponent from '../cmps/charts/PieChartComponent';
import { ChartOptions } from 'chart.js';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select";
import { useTranslation } from 'react-i18next';
import { taskService } from '../services/task.service'; // Make sure this service is correctly implemented
import { Loader } from '../cmps/loadersAndIcons/Loader';
import { SearchDurationInChart } from '../cmps/modals/SearchDurationInChart';
// Define the Task type if it's not already defined
interface Task {
    name: string;
    column_values: { id: string; text: string }[];
}

// Define the LabelDataPair type for mapping and sorting the chart data
interface LabelDataPair {
    label: string;
    data: number;
}

interface ChartProps {
    boardID: string;
}


const ChartIndex: React.FC<ChartProps> = ({ boardID }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Time Spent on Tasks',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
        }],
    });
    const rawUserType = localStorage.getItem('userType_rom');
    const userType = rawUserType ? rawUserType.replace(/^"|"$/g, '') : 'Unknown';
    const [filter, setFilter] = useState('all');
    const [chartType, setChartType] = useState('bar');
    const [t] = useTranslation("global");
    const [isLoading, setIsLoading] = useState(false)
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const options: ChartOptions = {
        scales: {
            y: {
                type: 'linear',
                beginAtZero: true,
            },
            x: {
                type: 'category',
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            },
        },
    };
    const fetchChartData = async (min: number = 0, max: number = Infinity) => {
        // setIsLoading(true);
        try {
            // Assuming your getChartData function is correctly typed

            const data = await taskService.getChartData(boardID, userType, filter, min, max);

            const labelDataPairs: LabelDataPair[] = data.labels.map((label, index) => ({
                label: label, // Assuming label is a string
                data: data.data[index] // Assuming data is an array of numbers
            }));

            labelDataPairs.sort((a, b) => b.data - a.data);

            const sortedLabels = labelDataPairs.map(pair => pair.label);
            const sortedDatasetsData = labelDataPairs.map(pair => pair.data);

            setChartData({
                labels: sortedLabels,
                datasets: [{
                    ...chartData.datasets[0],
                    data: sortedDatasetsData
                }]
            });
        } catch (error) {
            console.error('Failed to fetch chart data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleSearch = async () => {
        const min = parseInt(minValue, 10);
        const max = parseInt(maxValue, 10);
        // Call fetchChartData with the min and max values
        await fetchChartData(min, max);
    };
    // useEffect(() => {
    //     const fetchChartData = async (min = 0, max = Infinity) => {
    //         try {
    //             setIsLoading(true)
    //             const data = await taskService.getChartData(boardID, userType, filter, min, max);
    //             // Process and set the chart data
    //             const sortedData = {
    //                 labels: data.labels,
    //                 data: data.data
    //             };


    //             // Pair the labels with the data
    //             const labelDataPairs = sortedData.labels.map((label, index) => ({
    //                 label,
    //                 data: sortedData.data[index]
    //             }));

    //             // Sort the pairs by data in descending order
    //             labelDataPairs.sort((a, b) => b.data - a.data);

    //             // Unpair the label-data pairs into separate arrays
    //             const sortedLabels = labelDataPairs.map(pair => pair.label);
    //             const sortedDatasetsData = labelDataPairs.map(pair => pair.data);

    //             // Set the sorted data to chart
    //             setChartData({
    //                 labels: sortedLabels,
    //                 datasets: [{
    //                     ...chartData.datasets[0],
    //                     data: sortedDatasetsData
    //                 }]
    //             });
    //             setIsLoading(false)

    //         } catch (error) {
    //             console.error('Failed to fetch chart data:', error);
    //         }
    //     };

    //     fetchChartData();
    // }, [boardID, userType, filter, chartData.datasets]);
    useEffect(() => {
        // Call the function without min and max parameters to fetch all data by default
        fetchChartData();
    }, [boardID, userType, filter]);
    const handleChartTypeChange = (value: string) => {
        setChartType(value);
    };

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    // if (isLoading) {
    //     return <Loader />
    // }

    return (
        <div>
            <div className='flex gap-2'>

                <Select value={chartType} onValueChange={handleChartTypeChange}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={t("chartPlaceholder")} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="bar">{t("chartOption1")}</SelectItem>
                        <SelectItem value="line">{t("chartOption2")}</SelectItem>
                        <SelectItem value="pie">{t("chartOption3")}</SelectItem>
                    </SelectContent>
                </Select>
                <Select value={filter} onValueChange={handleFilterChange}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={t("filterPlaceholder")} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="all">{t("filterOptionAll")}</SelectItem>
                        <SelectItem value="positiveNumbers">{t("filterOptionPositive")}</SelectItem>
                    </SelectContent>
                </Select>
                <SearchDurationInChart onSearch={handleSearch} setMinValue={setMinValue} setMaxValue={setMaxValue} />
            </div>

            {chartType === 'bar' && <BarChartComponent data={chartData} options={options} />}
            {chartType === 'line' && <LineChartComponent data={chartData} options={options} />}
            {chartType === 'pie' && <PieChartComponent data={chartData} options={options} />}
        </div>
    );
};

export default ChartIndex;
