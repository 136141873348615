import React, { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { getusers, setReportDetails } from '../../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../../store/index';
import { useTranslation } from 'react-i18next';

import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,


} from "@tanstack/react-table";
import { Button } from "../../components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table";
import { Icon } from '../loadersAndIcons/Icon';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../loadersAndIcons/Loader';

export type ViewRecord = {
    employeeName: string;
    numOfLogs: string;
    numOfWorkingDays: string;
    userID: string;
    totalHours: string;
    date: string;
};


interface UsersManageTable2Props {
    startDate: string;
    endDate: string;
    selectedUser: string;
    isSearchButtonClicked: boolean;
}

export function ReportsTable({ reports, startDate, endDate, selectedUser, isSearchButtonClicked }: UsersManageTable2Props) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');

    // const reports = useSelector((storeState: RootState) => storeState.taskModule.reports);
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();


    const navigate = useNavigate()
    function goToReportDetails(date: string, userID: string) {

        const report = {
            employeeID: userID,
            startDate: date,
            endDate: date


        }


        dispatch(setReportDetails(report));
        const queryParams = new URLSearchParams({
            employeeID: userID,
            startDate: date,
            endDate: date,

        });

        navigate(`/report-details?${queryParams.toString()}`)


    }
    const { t, i18n } = useTranslation('global');

    const columns: ColumnDef<ViewRecord>[] = [
        {
            accessorKey: "employeeName",
            header: t("reportsTableColumn1"),
            cell: info => info.getValue(),
            enableSorting: true,
        },
        {
            accessorKey: "numOfLogs",
            header: t("reportsTableColumn2"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "totalHours",
            header: t("reportsTableColumn3"),
            cell: info => {
                if (info.getValue().startsWith("-")) {

                    return "";
                } else {
                    return info.getValue();
                }
            },
        },


        {
            accessorKey: "date",
            header: t("reportsTableColumn4"),
            cell: info => info.getValue(),
        },
        {
            accessorKey: "details",
            header: t("reportsTableColumn5"),
            cell: (info) => (
                <Icon name="BookText" className='cursor-pointer' onClick={() => goToReportDetails(info.row.original.date, info.row.original.userID)} />
            ),
        },

    ];



    const table = useReactTable({
        data: reports || [],
        columns,
        state: { sorting, globalFilter },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    useEffect(() => {
        const fetchUserData = async () => {
            dispatch(getusers())

        };

        fetchUserData();
    }, []);

    const isRtl = i18n.language === 'he';

    if (startDate === "" || endDate === "" || selectedUser === "" && !isSearchButtonClicked) {
        return (
            <div className="flex flex-col items-center justify-center mt-36" dir={isRtl ? "rtl" : "ltr"}>
                <div className='flex gap-2'>

                    <h1>{t("reportsTitleWhenThereAreNoReports")}</h1>
                    <Icon name='MousePointerSquare' />
                </div>
            </div>
        );
    }
    if (!reports) {
        return (
            <div className="flex flex-col items-center justify-center mt-36" dir={isRtl ? "rtl" : "ltr"}>
                <div className='flex gap-2'>

                    <h1>{t("reportsTitleWhenThereAreNoReports")}</h1>
                    <Icon name='MousePointerSquare' />
                </div>
            </div>);
    }
    if (isSearchButtonClicked) {
        return (
            <div className="custom-loader"></div>
        );
    }


    return (

        <div className="w-full" dir={isRtl ? "rtl" : "ltr"}>

            <div className="flex items-center py-4">

            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="text-center">
                                    {t("noResults")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4" dir={isRtl ? "rtl" : "ltr"}>
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getRowModel().rows.length} {t("of")} {reports.length} {t("records")}.
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {t("prevButton")}
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {t("nextButton")}
                    </Button>
                </div>
            </div>
        </div>

    );
}
