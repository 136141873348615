
export const utilService = {
    makeId,
    appendQueryParams,
    getData,
    formatLanguage,
    getDepartmentName
}




function makeId(length = 5) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}



function appendQueryParams(url: string, params: Record<string, any>): URL {
    const apiUrl = new URL(url);
    for (const key in params) {
        if (params[key] !== undefined && params[key] !== null) {
            apiUrl.searchParams.append(key, params[key]);
        }
    }
    return apiUrl;
}

async function getData(response: Response): Promise<any> {
    let responseData = await response.text();
    if (responseData.charCodeAt(0) === 0xfeff) {
        responseData = responseData.substr(1);
    }
    const trimmedData = responseData.trim();
    const json = JSON.parse(trimmedData);
    return json;
}







function formatLanguage(lang: string) {
    if (lang === "en") {
        return "English";
    } else if (lang === "he") {
        return "עִברִית";
    }
    return lang;
}




function getDepartmentName(departmentId: number): string {
    const departmentMapping: { [key: number]: string } = {
        1: "תכנון",
        2: "ייצור",
        3: "פיניש ובדיקה"
    };

    return departmentMapping[departmentId] || "Unknown";
}

























