import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { taskReducer } from './reducers/task.reducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        gStore?: typeof store;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    taskModule: taskReducer,

});
export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

window.gStore = store;
