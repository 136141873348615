import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { useToast } from "../components/ui/use-toast"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../components/ui/alert-dialog"
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../store/actions/task.actions';
import { AnyAction } from 'redux';
import { RootState } from '../store/index';
import { Icon } from './loadersAndIcons/Icon';
import { useTranslation } from 'react-i18next';

type DialogDemoProps = {
    userID: string;
};
export function DialogDemo({ userID }: DialogDemoProps) {
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

    const { toast } = useToast()



    async function handleDelete() {
        try {

            dispatch(deleteUser(userID));
            showToast(userID)
        } catch (err) {
            console.log("err", err)
            showToastError()
        }


    }

    const [t, i18n] = useTranslation("global");
    function showToast(userID: string) {
        toast({
            title: t("toast15Title"),
            description: `${t("toast15Description")} ${userID}`,
        })
    }
    function showToastError() {
        toast({
            variant: "destructive",
            title: t("toast16Title"),
            description: t("toast16Description")
        })
    }


    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>

                <Icon name='Trash2' className="cursor-pointer" />
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{t("deleteUserModalTitle")}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {t("deleteUserModalDescription")}

                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        {t("deleteUserModalCancel")}
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={handleDelete}>
                        {t("deleteUserModalDelete")}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )

}
