import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

// Define the type for our chart data
interface BarChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
    }[];
}

// Define the props for our component
interface BarChartProps {
    data: BarChartData;
    options: ChartOptions;
}

const BarChartComponent: React.FC<BarChartProps> = ({ data, options }) => {
    return (
        <>
            {/* <h2>Bar Chart</h2> */}
            <Bar data={data} options={options} />
        </>
    );
};

export default BarChartComponent;
