import { storageService } from "./storage.service";
import { utilService } from "./util.service";

export const taskService = {
    query,
    queryTest,
    updateReportDuration,
    removeUser,
    getIpAddress,
    formatDate,
    getChartData,
    logout,
    getUsersData,
    isUserActive,
    startTask,
    endTask,
    getEmployeesStatus,
    updateFCMToken,
    createWorker,
    getAllUsers,
    addUser,
    getReports,
    editUser,
    getReportDetails,
    insertLogToUpdateLogs,
    getLogFromUpdateLogs,
    searchReportsByBoardNumber,

}


interface Task {
    id: string;
    name: string;
    column_values: ColumnValue[];
}

interface ColumnValue {
    id: string;
    text: string;
}


interface EndTaskData {
    token: string;
    boardName: string;
    itemID: string;
    msg: string;
    username: string;
    taskName: string;
    taskID: string;
    from: string;
    to: string;
    amount: number;
    date: string;

}
interface FCMTokenData {
    fcmToken: string,
    token: string
}
interface StartTaskData {
    token: string
}
interface AddUserData {
    firstName: string,
    lastName: string
}
interface UpdateUserData {
    firstName: string,
    lastName: string,
    userID: string,
    pwdHash: string,
    isActive: string,
}


interface UserDetails {
    startDate: string;
    endDate: string;
    employeeID: string;
}

interface UpdatedReport {
    startDate: string,

    endDate: string,
    startTime: string,

    endTime: string,
    workLogID: string
}

interface LogDetails {
    workLogID: string,
    token: string
}

const SUBDOMAIN = "https://rom.lan-wan.net/mondayTasks/backend/"
async function fetchData(apiUrl: string, queryParams: Record<string, any> = {}): Promise<any> {
    const rowUserToken = localStorage.getItem('userID_rom');
    const token = rowUserToken ? rowUserToken.replace(/^"|"$/g, '') : 'Unknown';

    // Append the token to queryParams
    queryParams.token = token;

    const urlWithParams = utilService.appendQueryParams(apiUrl, queryParams);
    console.log("API URL with params: " + urlWithParams);

    try {
        const response = await fetch(urlWithParams, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const parsedData = await utilService.getData(response);
        return parsedData;
    } catch (error) {
        console.error("error", error);
        throw error;
    }
}



async function query(boardID: string, userType: string) {
    let allKeys = Object.keys(localStorage);


    let timerStartTimes = allKeys
        .filter(key => key.startsWith('timerStartTime-'))
        .map(key => key.split('-')[1]); // This will contain all the numbers after "timerStartTime-"


    const queryData = {
        boardID,
        userType
    }
    const res = await fetchData(`${SUBDOMAIN}users/getTasks_.php`, queryData);
    const taskID = res.currTaskID

    if (taskID === "") {

        storageService.remove(`timerStartTime-${timerStartTimes[0]}`, false);
    }

    return res.data.items_page_by_column_values.items;
}
async function queryTest(boardID: string) {
    const res = await fetchData(`https://rom.lan-wan.net/mondayTasks/backend/getStuckTasks.php`, { boardID });
    return res.data.items_page_by_column_values.items;
}

async function getReports(userDetails: UserDetails) {
    const res = await fetchData(`${SUBDOMAIN}reports/getMainReports.php`, userDetails);
    return res
}
async function getReportDetails(reportDetails: UserDetails) {
    const res = await fetchData(`${SUBDOMAIN}reports/getReportsDetails.php`, reportDetails);
    return res
}
async function updateReportDuration(updatedReport: UpdatedReport) {
    const res = await fetchData(`${SUBDOMAIN}reports/updateLog.php`, updatedReport);
    return res
}

async function insertLogToUpdateLogs(log: LogDetails) {
    const res = await fetchData(`${SUBDOMAIN}reports/insertLog.php`, log);
     
    return res
}
async function getLogFromUpdateLogs(workLogID: string) {
    const res = await fetchData(`${SUBDOMAIN}reports/getUpdatedLogs.php`, { workLogID });
     
    return res
}

async function searchReportsByBoardNumber(boardName: string) {
    const res = await fetchData(`${SUBDOMAIN}reports/getBoardNumberReports.php`, { boardName });
     
    return res
}








async function logout(userID: string) {
    const res = await fetchData(`${SUBDOMAIN}login/logout.php`, { userID });
     
    return res;
}


async function getUsersData() {
    const res = await fetchData(`${SUBDOMAIN}getAppViews.php`);
     
    return res;
}
async function getAllUsers() {
    const res = await fetchData(`${SUBDOMAIN}users/getUsers.php`);
     
    return res;
}
async function addUser(userData: AddUserData) {
    const res = await fetchData(`${SUBDOMAIN}users/insertUser.php`, userData);
     
    return res

}
async function editUser(userData: UpdateUserData) {
    const res = await fetchData(`${SUBDOMAIN}users/updateUser.php`, userData);
     

}
async function removeUser(userID: string) {
    await fetchData(`${SUBDOMAIN}users/deleteUser.php`, { userID });

}
async function startTask(startTaskData: StartTaskData) {

    await fetchData(`${SUBDOMAIN}startBoard.php`, startTaskData);

}
async function endTask(endTaskData: EndTaskData) {
    const res = await fetchData(`${SUBDOMAIN}endBoard.php`, endTaskData);
    //  
    // return res;
}
async function getEmployeesStatus() {
    const res = await fetchData(`${SUBDOMAIN}getEmployeesStatus.php`);
     
    return res;
}
async function updateFCMToken(data: FCMTokenData) {
    const res = await fetchData(`${SUBDOMAIN}login/updateFCMToken.php`, data);
     
    return res;
}





async function getIpAddress() {
    try {
        const response = await fetch('https://api.ipify.org/?format=json');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        return data.ip;
    } catch (error) {
        console.error('There was a problem fetching the IP address:', error);
    }
}


function formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

async function getChartData(boardID: string, userType: string, filter: string, min: number, max: number) {
    const theData = {
        boardID,
        userType
    };
    try {
        const res = await fetchData(`${SUBDOMAIN}users/getTasks_.php`, theData);

        let filteredData = res.data.items_page_by_column_values.itemss;
        // if (filter === 'range') { // Assuming 'range' is a type of filter you have defined for this purpose
        //     filteredData = res.data.filter((task) => {
        //         const numbersColumn = task.column_values.find((column) => column.id === 'numbers6');
        //         const numberValue = numbersColumn ? parseInt(numbersColumn.text, 10) || 0 : 0;
        //         return numberValue >= min && numberValue <= max;
        //     });
        // }

        const labels = filteredData.map((task) => task.name);
        const data = filteredData.map((task) => {
            const numbersColumn = task.column_values.find((column) => column.id === 'numbers6');
            return numbersColumn ? parseInt(numbersColumn.text, 10) || 0 : 0;
        });

        return { labels, data };
    } catch (error) {
        console.error('Failed to fetch chart data:', error);
        throw error; // or return an appropriate value indicating the error
    }
}

// task.service.js
function isUserActive() {
    try {
        console.log("Checking user activity");
        const currDate = Date.now();
        const theDate = storageService.load('date_rom', null, true);

        if (!theDate) return false;

        let loginDate = Number(theDate);
        const diff = currDate - loginDate;
        const diffInHours = diff / (1000 * 60 * 60);

        if (diffInHours >= 24) {
            storageService.remove('fullName_rom');
            storageService.remove('fullName_rom', true);
            storageService.remove('date_rom');
            storageService.remove('date_rom', true);
            storageService.remove('userID_rom');
            storageService.remove('userID_rom', true);
            return false;
        } else {
            storageService.store('date_rom', currDate.toString(), true);
            storageService.store('date_rom', currDate.toString());
            return true;
        }
    } catch (error) {
        console.error('An error occurred in isUserActive:', error);
        return false;
    }
}



function createWorker(boardName: string, employeeName: string, userID: string) {
    return {
        boardName,
        employeeName,
        userID,
        startDateTime: Date.now(),
        workLogID: userID,
    }
}