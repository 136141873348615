
import React from 'react';

interface TimerDisplayProps {
    timer: number;
    whereToShow: string;
}

export const TimerDisplay: React.FC<TimerDisplayProps> = ({ timer, whereToShow }) => {

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return [hours, minutes, seconds]
            .map(unit => unit < 10 ? `0${unit}` : unit)
            .join(':');
    };

    return (
        <div className={whereToShow === "boss-data" ? "inline-block  border border-red-900 " : "bg-white text-black p-2 rounded shadow-md"}>
            <p className={whereToShow !== "boss-data" ? "text-md font-mono" : "font-bold text-4xl"}> {formatTime(timer)}</p>
        </div >
    );
};
