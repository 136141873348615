import { useNavigate } from "react-router-dom"
import { Button } from "../components/ui/button"

import {
    Sheet,

    SheetContent,

    SheetTrigger,
} from "../components/ui/sheet"
import { AlertDialogDemo } from "./AlertDialogDemo"
import { ModeToggle } from "./ModeToggle"
import { Icon } from "./loadersAndIcons/Icon"
import { useState } from "react"
import { useTranslation } from 'react-i18next';
import { LanguageSelect } from "./LanguageSelect"
import { storageService } from "../services/storage.service"

export function MobileNavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const { i18n } = useTranslation();
    const [t] = useTranslation("global")
    const setLanguage = (language: string | undefined) => {
        i18n.changeLanguage(language);
        storageService.store('language_rom', language);
    };
    const navigate = useNavigate()

    function goTo(to: string) {
        navigate(to)
        closeMobileNavBar()

    }


    function closeMobileNavBar() {
        setIsOpen(false);

    }



    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
                <Icon name="Menu" className="cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
            </SheetTrigger>
            <SheetContent>

                {/* <div className=""> */}
                <div className="flex flex-col items-start gap-4">
                    <Button onClick={() => goTo("/home")} >
                        {t("appHeaderRoute1")}
                    </Button>
                    {/* <Button onClick={() => goTo("/chart")}>
                        {t("appHeaderRoute2")}
                    </Button> */}

                    <Button onClick={() => goTo("/users-data")}>
                        {t("appHeaderRoute3")}
                    </Button>
                    <Button onClick={() => goTo("/users-manage")}
                    >
                        {t("appHeaderRoute4")}
                    </Button>
                    <Button onClick={() => goTo("/reports")}
                    >
                        {t("appHeaderRoute5")}

                    </Button>
                    <Button onClick={() => goTo("/users")}
                    >
                        {t("appHeaderRoute6")}

                    </Button>
                    {/* <DialogDemo /> */}

                    <ModeToggle />

                    <AlertDialogDemo />
                    <LanguageSelect setLanguage={setLanguage} />


                </div>

                {/* </div> */}

            </SheetContent>
        </Sheet>
    )
}
